import Button from "../../components/Button";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../App";
import { request } from "../../helpers/request";
import { colors } from "../../helpers/maincolors";
import { TextField } from "@mui/material";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";

export default function AdminUsers({ navigation }) {
  const { admin } = useContext(Context);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [showEditContainer, setShowEditContainer] = useState(undefined);
  const [subject, setSubject] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [completeHtml, setCompleteHtml] = useState();

  async function handleArrowClick(action) {
    try {
      const userResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/getallusers",
        data: {
          page: action === "previous" ? page - 1 : page + 1,
          userId: admin.user.id,
          ...(admin.token && { adminToken: admin.token }),
        },
      });

      action === "previous" ? setPage(page - 1) : setPage(page + 1);
      setUsers(userResult.users);
    } catch (e) {}
  }
  async function getUserData() {
    try {
      const userResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/getallusers",
        data: {
          page,
          userId: admin.user.id,
          ...(admin.token && { adminToken: admin.token }),
        },
      });
      setMaxPage(userResult.maxPage);
      setUsers(userResult.users);
    } catch (e) {}
  }
  async function getEmailList() {
    const response = await request({
      reqtype: "POST",
      endpoint: "/api/admin/emaillist",
      data: {
        userId: admin.user.id,
        adminToken: admin.token,
      },
    });
    if (response?.emails) {
      setEmailList(response.emails);
    }
  }
  function findEmailTemplateByName(value) {
    return emailList.find((item) => item["name"] === value);
  }

  useEffect(() => {
    if (findEmailTemplateByName("afterPayment")?.subject) {
      setSubject(findEmailTemplateByName("afterPayment")?.subject);
    }
    setCompleteHtml(`<div>
      <div style="word-wrap: break-word;line-height: 18px;">
        <div style="padding: 12px;">
        <!--BODY ENTRY-->
        <p>${new Date().getHours() < 12 ? "Καλημέρα" : "Καλησπέρα"}.</p><br />
       ${findEmailTemplateByName("afterPayment")?.body || ""}
        <!--BODY END-->
          <br />
          ${findEmailTemplateByName("furtherquestions")?.body}
          <br /> 
          ${findEmailTemplateByName("signatur")?.body}
        </div>
      </div> 
      ${findEmailTemplateByName("footer")?.body}
      %pixel-open%
    </div>`);
  }, [emailList]);

  async function sendActivation(queryTargeting) {
    try {
      const response = await request({
        reqtype: "POST",
        endpoint: "/api/admin/sendmail",
        data: {
          queryTargeting,
          type: "users",
          subject: subject,
          body: completeHtml,
          userId: admin.user.id,
          adminToken: admin.token,
        },
      });
      if (response.info === "success") {
        toast.success("Μαιλ στάλθηκε");
      } else if (response.info === "nothing found") {
        toast.error("Not found");
      }
    } catch (e) {
      console.log("fail", e);
    }
  }

  useEffect(() => {
    getUserData();
    getEmailList();
  }, []);

  return users.length > 0 ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: 40,
        }}
      >
        {maxPage > page && (
          <Button
            color="#000000"
            children="->"
            onClick={() => handleArrowClick("next")}
          />
        )}
        {page > 1 && (
          <Button
            color="#000000"
            children="<-"
            onClick={() => handleArrowClick("previous")}
          />
        )}
      </div>
      <TextField
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.nativeEvent.target.value)}
        label="Search"
      />
      {users.map((el, i) => {
        if (el.firstName.toUpperCase().includes(searchQuery.toUpperCase())) {
          return (
            <div
              style={{
                padding: 5,
                marginBottom: 10,
              }}
              key={i}
            >
              <Button
                color={
                  el.subscription.length > 0 && el.owns.length > 0
                    ? colors.blue
                    : el.subscription.length > 0
                    ? colors.gold
                    : "#666666"
                }
                children={el.firstName}
                onClick={() => {
                  if (showEditContainer === el.username) {
                    setShowEditContainer(undefined);
                  } else {
                    setShowEditContainer(el.username);
                  }
                }}
              />
              <p style={{ fontSize: 11 }}>{el.username}</p>
              {showEditContainer === el.username && (
                <div style={{ margin: 20 }}>
                  <div>Name: {el.firstName}</div>
                  <div>Email: {el.username}</div>
                  <div>Verified: {JSON.stringify(el.verified)}</div>
                  <div>
                    Subscribed: {el.subscription.length > 0 ? "yes" : "no"}
                  </div>
                  <div>Owns: {el.owns.length > 0 ? "yes" : "no"}</div>
                  {el.owns.length > 0 &&
                    el.owns.map((el) => {
                      return <p>Company Id: {el.companyId}</p>;
                    })}
                  {el.subscription.length > 0 && (
                    <div>
                      <div>
                        Used Subscription:
                        {JSON.stringify(el.subscription[0]?.used)}
                      </div>
                      <div>
                        Platform: {JSON.stringify(el.subscription[0]?.platform)}
                      </div>
                    </div>
                  )}

                  {((el.subscription.length > 0 && el.owns.length === 0) ||
                    el.username === "miketheboy11@gmail.com") && (
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={() => sendActivation({ username: el.username })}
                      size="small"
                      children="Send Activation"
                    />
                  )}
                </div>
              )}
            </div>
          );
        }
      })}
    </div>
  ) : (
    <Loader />
  );
}
