import "./AdminNewsletter.css";
import Button from "../../components/Button";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../App";
import { request } from "../../helpers/request";
import { TextField, Switch, MenuItem } from "@mui/material";
import toast from "react-hot-toast";
import parse from "html-react-parser";
import { colors } from "../../helpers/maincolors";

export default function AdminNewsletter() {
  const { admin } = useContext(Context);
  const [type, setType] = useState("none");
  const [body, setBody] = useState("");
  const [checked, setChecked] = useState(false);
  const [city, setCity] = useState("");
  const [sendEnabled, setSendEnabled] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  const [completeHtml, setCompleteHtml] = useState();
  const [template, setTemplate] = useState("none");
  const [footerEnabled, setFooterEnabled] = useState(true);
  const [signaturEnabled, setSignaturEnabled] = useState(true);
  const [showHTML, setShowHTML] = useState(false);
  const [email, setEmail] = useState("undefined");
  const [called, setCalled] = useState("undefined");
  const [converted, setConverted] = useState("undefined");
  const [interested, setInterested] = useState("undefined");
  const [contacted, setContacted] = useState("undefined");
  const [tracking, setTracking] = useState("undefined");
  const [opened, setOpened] = useState("undefined");
  const [cancelled, setCancelled] = useState("undefined");
  const [subject, setSubject] = useState("");
  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    if (findEmailTemplateByName(template)?.subject) {
      setSubject(findEmailTemplateByName(template)?.subject);
    }
    setCompleteHtml(`<div>
      <div style="word-wrap: break-word;line-height: 18px;">
        <div style="padding: 12px;">
        <!--BODY ENTRY-->
        <p>${new Date().getHours() < 12 ? "Καλημέρα" : "Καλησπέρα"}.</p><br />
        ${body} ${findEmailTemplateByName(template)?.body || ""}
        <!--BODY END-->
          <br />
          ${
            footerEnabled
              ? findEmailTemplateByName("furtherquestions")?.body
              : ""
          }
          <br /> 
          ${footerEnabled ? findEmailTemplateByName("signatur")?.body : ""}
        </div>
      </div> 
      ${signaturEnabled ? findEmailTemplateByName("footer")?.body : ""}
      ${type === "sales" ? findEmailTemplateByName("unsubscribe")?.body : ""}
      %pixel-open%
    </div>`);
  }, [
    type,
    body,
    footerEnabled,
    template,
    signaturEnabled,
    subject,
    emailList,
  ]);

  async function sendNewsletter() {
    const queryTargeting = {
      ...(email !== "undefined" && { email }),
      ...(called !== "undefined" && { called }),
      ...(interested !== "undefined" && { interested }),
      ...(converted === "true"
        ? { converted }
        : { converted: { $exists: false } }),
      ...(cancelled !== "undefined" && { cancelled }),
      ...(city !== "" && { location: city }),
      ...(contacted === "true"
        ? { "contacted.0": { $exists: true } }
        : contacted === "false" && { "contacted.0": { $exists: false } }),
      ...(tracking === "true"
        ? { "tracking.0": { $exists: true } }
        : tracking === "false" && { "tracking.0": { $exists: false } }),
      ...(opened === "true"
        ? { "opened.0": { $exists: true } }
        : opened === "false" && { "opened.0": { $exists: false } }),
    };
    setSendEnabled(false);
    if (type && checked && subject) {
      try {
        const response = await request({
          reqtype: "POST",
          endpoint: "/api/admin/sendmail",
          data: {
            queryTargeting,
            type: type.trim().toLowerCase(),
            subject,
            body: completeHtml,
            userId: admin.user.id,
            adminToken: admin.token,
          },
        });
        if (response.info === "success") {
          setChecked(!checked);
          toast.success("Όλα τα Μαιλ στάλθηκαν");
        } else if (response.info === "nothing found") {
          toast.error("Not found");
        }
      } catch (e) {
        console.log("fail", e);
      }
    } else {
      toast.error("Problem! Is checked? Is type set?");
    }
  }

  async function getEmailList() {
    const response = await request({
      reqtype: "POST",
      endpoint: "/api/admin/emaillist",
      data: {
        userId: admin.user.id,
        adminToken: admin.token,
      },
    });
    if (response?.emails) {
      setEmailList(response.emails);
    }
  }

  function findEmailTemplateByName(value) {
    return emailList.find((item) => item["name"] === value);
  }

  useEffect(() => {
    getEmailList();
  }, []);

  return (
    <div className="AdminNewsletter">
      <TextField
        value={type}
        label="type"
        onChange={(e) => setType(e.target.value)}
        select
      >
        <MenuItem value="sales">Sales</MenuItem>
        <MenuItem value="companies">Companies</MenuItem>
        <MenuItem value="users">Users</MenuItem>
      </TextField>

      {/sales|users/.test(type) && (
        <TextField
          value={email}
          onChange={(e) => setEmail(e.nativeEvent.target.value)}
          label={`Email: to ${email}`}
        />
      )}
      <TextField
        value={subject}
        onChange={(e) => setSubject(e.nativeEvent.target.value)}
        label="subject *"
      />

      <div
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {showHTML && (
          <TextField
            style={{ width: "48%" }}
            multiline
            value={completeHtml}
            onChange={(e) => setCompleteHtml(e.nativeEvent.target.value)}
            label={`Message to ${type ? type : "receiver"}`}
          />
        )}
        {completeHtml && (
          <div
            style={{
              border: `5px solid ${colors.main}`,
              width: showHTML ? "48% " : "100%",
            }}
          >
            {parse(completeHtml)}
          </div>
        )}
      </div>

      {!showHTML && template === "none" && (
        <TextField
          multiline
          value={body}
          onChange={(e) => setBody(e.nativeEvent.target.value)}
          label={`Message to ${type ? type : "receiver"}`}
        />
      )}

      {/sales|miketheboy11@gmail.com/.test(type) && (
        <div style={{ display: "flex", gap: "10px" }}>
          <TextField
            fullWidth
            multiline
            value={city}
            onChange={(e) => setCity(e.nativeEvent.target.value)}
            label="City"
          />

          <TextField
            fullWidth
            select
            value={called}
            label="called"
            onChange={(e) => setCalled(e.target.value)}
          >
            <MenuItem value="true">Called - true </MenuItem>
            <MenuItem value="false">Called - false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>

          <TextField
            fullWidth
            select
            value={interested}
            label="interested"
            onChange={(e) => setInterested(e.target.value)}
          >
            <MenuItem value="true">interested - true </MenuItem>
            <MenuItem value="false">interested - false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>

          <TextField
            fullWidth
            select
            value={contacted}
            label="contacted"
            onChange={(e) => setContacted(e.target.value)}
          >
            <MenuItem value="true">contacted - true </MenuItem>
            <MenuItem value="false">contacted - false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>
          <TextField
            fullWidth
            select
            value={tracking}
            label="tracking"
            onChange={(e) => setTracking(e.target.value)}
          >
            <MenuItem value="true">tracking - true </MenuItem>
            <MenuItem value="false">tracking - false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>
          <TextField
            fullWidth
            select
            value={opened}
            label="opened"
            onChange={(e) => setOpened(e.target.value)}
          >
            <MenuItem value="true">opened - true </MenuItem>
            <MenuItem value="false">opened - false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>

          <TextField
            fullWidth
            onChange={(e) => setConverted(e.target.value)}
            value={converted}
            label="converted"
            select
          >
            <MenuItem value="true">true </MenuItem>
            <MenuItem value="false">false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>

          <TextField
            fullWidth
            value={cancelled}
            onChange={(e) => setCancelled(e.target.value)}
            label="Cancelled"
            select
          >
            <MenuItem value="true">true </MenuItem>
            <MenuItem value="false">false</MenuItem>
            <MenuItem value="undefined"></MenuItem>
          </TextField>
        </div>
      )}
      <div className="AdminNewsletter-Switches">
        <div>
          <div>
            <p>Show Raw HTML</p>
            <Switch value={showHTML} onChange={() => setShowHTML(!showHTML)} />
          </div>

          <TextField
            value={template}
            label="template"
            onChange={(e) => setTemplate(e.target.value)}
            select
          >
            {emailList.length > 0 &&
              emailList.map((el, i) => {
                return (
                  <MenuItem key={i} value={el.name}>
                    {el.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </div>
        <div>
          <p>Mobile View</p>
          <Switch
            value={mobileView}
            onChange={() => setMobileView(!mobileView)}
          />
        </div>
        <div>
          <p>Disable Footer</p>
          <Switch
            value={footerEnabled}
            onChange={() => setFooterEnabled(!footerEnabled)}
          />
        </div>
        <div>
          <p>Disable Signatur</p>
          <Switch
            value={signaturEnabled}
            onChange={() => setSignaturEnabled(!signaturEnabled)}
          />
        </div>
      </div>

      <p>Confirm to send *</p>
      <Switch value={checked} onChange={() => setChecked(!checked)} />

      <Button
        disabledStyle={
          type && type.length > 0 && checked && sendEnabled ? false : true
        }
        onClick={() => sendNewsletter()}
        size="large"
        children="send newsletter to all selected"
      />
    </div>
  );
}
