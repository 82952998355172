import { Link } from "react-router-dom";
import "./Footer.css";
export default function Footer() {
  const admin = JSON.parse(localStorage.getItem("mygreekappadmin"));
  return (
    <footer className="footer">
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>
      <ul className="menu">
        <Link className="menu__item" to="/">
          <p className="menu__link">Αρχική</p>
        </Link>
        <Link className="menu__item" to="/imprint">
          <p className="menu__link">Αποτύπωμα & Επικοινωνία</p>
        </Link>
        <Link className="menu__item" to="/privacy">
          <p className="menu__link">Όροι και Πολιτική Προστασίας</p>
        </Link>
        {admin && (
          <Link
            className="menu__item"
            to={`/${admin.expires > Date.now() ? "overview" : "rl"}`}
          >
            <p className="menu__link">{`${
              admin.expires > Date.now() ? "Admin" : "Login"
            }`}</p>
          </Link>
        )}
      </ul>
    </footer>
  );
}
