import { useEffect, useState } from "react";
import { request } from "../../helpers/request";
import Button from "../../components/Button";
import toast from "react-hot-toast";
import { TextField, Switch, Select, MenuItem } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

export default function AdminSales() {
  const admin = JSON.parse(localStorage.getItem("mygreekappadmin"));
  const [page, setPage] = useState(1);
  const [newSales, setNewSales] = useState(false);
  const [salesList, setSalesList] = useState([]);
  const [countObject, setCountObject] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  // create sales start
  const [companyNameOne, setCompanyNameOne] = useState("");
  const [personContactNameOne, setPersonContactNameOne] = useState("");
  const [telOne, setTelOne] = useState("");
  const [emailOne, setEmailOne] = useState("");
  const [latOne, setLatOne] = useState("");
  const [lonOne, setLonOne] = useState("");
  const [categoryOne, setCategoryOne] = useState("");
  //create sales end
  const [eml, setEml] = useState("");
  const [cmp, setCmp] = useState("");

  //edit sales start
  const [companyName, setCompanyName] = useState({ v: "", i: "" });
  const [personContactName, setPersonContactName] = useState({ v: "", i: "" });
  const [email, setEmail] = useState({ v: "", i: "" });
  const [category, setCategory] = useState({ v: "", i: "" });
  const [location, setLocation] = useState({ v: "", i: "" });
  const [searchPlace, setSearchPlace] = useState("");
  const [tel, setTel] = useState({ v: "", i: "" });
  const [lat, setLat] = useState({ v: "", i: "" });
  const [lon, setLon] = useState({ v: "", i: "" });
  // edit sales end
  const [showAmount, setShowAmount] = useState(false);
  const [showEditContainer, setShowEditContainer] = useState(undefined);
  const [serverCategories, setServerCategories] = useState();
  const [fullQuery, setFullQuery] = useState("");

  // coldcall
  const [called, setCalled] = useState({ v: "", i: "" });
  const [reached, setReached] = useState({ v: "", i: "" });
  const [converted, setConverted] = useState({ v: "", i: "" });
  const [interested, setInterested] = useState({ v: "", i: "" });
  const [callback, setCallback] = useState({ v: "", i: "" });
  const [notes, setNotes] = useState({ v: "", i: "" });
  const [duration, setDuration] = useState({ v: "", i: "" });

  async function getSalesList(obj) {
    try {
      const salesListResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/getsaleslist",
        data: {
          page:
            obj?.action === "previous"
              ? page - 1
              : obj?.action === "next"
              ? page + 1
              : page,
          userId: admin.user.id,
          adminToken: admin.token,
        },
      });
      obj?.action === "previous"
        ? setPage(page - 1)
        : obj?.action === "next"
        ? setPage(page + 1)
        : console.log("");
      if (salesListResult) {
        setCountObject(salesListResult.countObject);
        setMaxPage(salesListResult.maxPage);
        setSalesList(salesListResult.salesList);
      }
    } catch (e) {}
  }

  async function getSalesListByEmailName() {
    try {
      const salesListResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/getsaleslistemailname",
        data: {
          ...(eml !== "" && { email: eml }),
          ...(cmp !== "" && { companyName: cmp }),
          userId: admin.user.id,
          adminToken: admin.token,
        },
      });
      setSalesList(salesListResult.salesList);
    } catch (e) {
      console.log(e);
    }
  }
  async function getFullSalesList(query) {
    try {
      const salesListResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/fullsaleslist",
        data: {
          userId: admin.user.id,
          adminToken: admin.token,
          query: `{${query}}`,
        },
      });
      if (salesListResult) {
        setSalesList(salesListResult.sales);
      }
    } catch (e) {}
  }

  async function createSalesEntry() {
    if (
      companyNameOne &&
      categoryOne.length > 0 &&
      searchPlace.value.structured_formatting.main_text
    ) {
      try {
        const response = await request({
          reqtype: "POST",
          endpoint: "/api/admin/createsales",
          data: {
            companyName: companyNameOne,
            personContactName: personContactNameOne,
            email: emailOne.trim().toLowerCase(),
            tel: telOne,
            category: categoryOne,
            location: searchPlace.value.structured_formatting.main_text,
            geometry: [lonOne, latOne],
          },
        });

        if (response.success) {
          toast.success("Επιτυχής");
          setPersonContactNameOne("");
          setCompanyNameOne("");
          setEmailOne("");
          setTelOne("");
        } else if (response.info === "already exists") {
          toast.error("Υπαρχει ειδη");
        } else {
          toast.error("Σφάλμα");
        }
      } catch (e) {
        toast.error("Σφάλμα");
        console.log("fail", e);
      }
    } else {
      toast.error("ERROR");
    }
  }

  async function setSearchPlaceResults(e, i) {
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (i) {
          setLat({ v: lat, i });
          setLon({ v: lng, i });
          setLocation({ i, v: e.value.structured_formatting.main_text });
        } else {
          setLatOne(lat);
          setLonOne(lng);
          setSearchPlace(e);
        }
      });
  }

  async function categoryRequest() {
    const srvCategories = await request({
      reqtype: "GET",
      endpoint: "/categories",
    });

    srvCategories.categories.sort(function (a, b) {
      return a.name.localeCompare(b.name, "el");
    });

    setServerCategories(srvCategories);
  }

  async function updateSales(obj) {
    const queryObject = {
      userId: admin.user.id,
      adminToken: admin.token,
      id: obj.id,
      companyName: obj.companyName,
      personContactName: obj.personContactName,
      email: obj.email,
      tel: obj.tel,
      category: obj.category,
      location: obj.location,
      interested: obj.interested,
      geometry: { type: "Point", coordinates: obj.coordinates },
      coldcall: {
        called: obj.called,
        reached: obj.reached,
        callback: obj.callback,
        converted: obj.converted,
        notes: obj.notes,
        duration: obj.duration,
      },
    };
    try {
      const updateResponse = await request({
        reqtype: "PATCH",
        endpoint: "/api/admin/updateSalesuser",
        data: queryObject,
      });
      if (updateResponse.result === "success") {
        toast.success("Επιτυχής");
        if (fullQuery) {
          getFullSalesList(fullQuery);
        } else {
          getSalesList();
        }
        setCompanyName({ v: "", i: "" });
        setPersonContactName({ v: "", i: "" });
        setEmail({ v: "", i: "" });
        setTel({ v: "", i: "" });
        setCategory({ v: "", i: "" });
        setLocation({ v: "", i: "" });
      } else {
        toast.error("Problem");
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  }
  async function deletePartnerSales(id) {
    try {
      const deletedResponse = await request({
        reqtype: "DELETE",
        endpoint: "/api/admin/deletesalespartner",
        data: {
          id,
          userId: admin.user.id,
          adminToken: admin.token,
        },
      });
      if (deletedResponse.status === "DELETED") {
        toast.success("DELETED");
        if (fullQuery) {
          getFullSalesList(fullQuery);
        } else {
          getSalesList();
        }
      } else {
        toast.error("Problem");
      }
    } catch (e) {
      console.log(e);
      toast.error("Problem");
    }
  }

  useEffect(() => {
    getSalesList();
    categoryRequest();
  }, []);

  return (
    <div>
      <div>
        <p>Create Sales Partner</p>
        <Switch value={newSales} onChange={() => setNewSales(!newSales)} />
      </div>
      {newSales ? (
        <>
          <div
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <GooglePlacesAutocomplete
              selectProps={{
                placeholder: "Type Adress or City",
                place: searchPlace,
                onChange: (e) => setSearchPlaceResults(e),
              }}
              apiKey={admin.googleApiKey || "hi"}
            />

            <TextField
              value={companyNameOne}
              onChange={(e) => setCompanyNameOne(e.nativeEvent.target.value)}
              label="Company Name"
              helperText="Required"
            />
            <TextField
              value={telOne}
              onChange={(e) => setTelOne(e.nativeEvent.target.value)}
              label="Tel"
            />
            <Select
              value={categoryOne}
              label="Category"
              onChange={(e) => setCategoryOne(e.target.value)}
            >
              {serverCategories?.categories &&
                serverCategories?.categories.map((el, i) => {
                  return (
                    <MenuItem key={i} value={el.name}>
                      {el.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <TextField
              value={personContactNameOne}
              onChange={(e) =>
                setPersonContactNameOne(e.nativeEvent.target.value)
              }
              label="Contact Person Name"
            />

            <TextField
              value={emailOne ? emailOne.toLowerCase() : emailOne}
              onChange={(e) => setEmailOne(e.nativeEvent.target.value)}
              label="Email"
              helperText="Required"
            />
            <Button
              children="Save - creating Sales Partner"
              onClick={createSalesEntry}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>Show how many Sales Partner per City</p>
              <Switch
                value={showAmount}
                onChange={() => setShowAmount(!showAmount)}
              />
            </div>

            <div>
              <TextField
                value={fullQuery}
                onChange={(e) => setFullQuery(e.nativeEvent.target.value)}
                label="Query"
                onKeyDown={(e) => {
                  e.key === "Enter" && getFullSalesList(fullQuery);
                }}
              />
            </div>
            <div>
              <TextField
                value={eml}
                onChange={(e) => setEml(e.nativeEvent.target.value)}
                label="Email"
                onKeyDown={(e) => {
                  e.key === "Enter" && getSalesListByEmailName();
                }}
              />
            </div>
            <div>
              <TextField
                value={cmp}
                onChange={(e) => setCmp(e.nativeEvent.target.value)}
                label="Company Name"
                onKeyDown={(e) => {
                  e.key === "Enter" && getSalesListByEmailName();
                }}
              />
            </div>
          </div>

          {countObject &&
            showAmount &&
            Object.entries(countObject)
              .sort()
              .map(([city, number], index) => {
                if (number) {
                  return (
                    <p key={index}>
                      {city} {number}
                    </p>
                  );
                }
              })}
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "100%",
              height: "2px",
              backgroundColor: "black",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: page > 1 ? "space-between" : "flex-end",
              flexDirection: "row",
              marginVertical: 20,
            }}
          >
            {page > 1 && (
              <Button
                color="#000000"
                children="<-"
                onClick={() => getSalesList({ action: "previous" })}
              />
            )}
            {maxPage > page && (
              <Button
                color="#000000"
                children="->"
                onClick={() => getSalesList({ action: "next" })}
              />
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {salesList.map((el, i) => {
              return (
                <div key={i}>
                  <Button
                    color={
                      showEditContainer === el.companyName
                        ? "#7CFC00"
                        : undefined
                    }
                    onClick={() => {
                      if (showEditContainer === el.companyName) {
                        setShowEditContainer(undefined);
                      } else {
                        setShowEditContainer(el.companyName);
                      }
                    }}
                  >
                    <p>{el.companyName}</p>
                  </Button>
                  {showEditContainer === el.companyName && (
                    <div
                      style={{
                        border: "3px solid black",
                        padding: 20,
                        margin: 20,
                      }}
                    >
                      <TextField
                        fullWidth
                        value={
                          companyName.i === el._id
                            ? companyName.v
                            : el.companyName
                        }
                        onChange={(e) =>
                          setCompanyName({
                            i: el._id,
                            v: e.nativeEvent.target.value,
                          })
                        }
                        label={`Company Name: ${
                          companyName.v !== "" ? companyName.v : el.companyName
                        }`}
                      />
                      <TextField
                        fullWidth
                        value={
                          personContactName.i === el._id
                            ? personContactName.v
                            : el.personContactName
                        }
                        onChange={(e) =>
                          setPersonContactName({
                            i: el._id,
                            v: e.nativeEvent.target.value,
                          })
                        }
                        label={`Person Name: ${
                          personContactName.v && personContactName.v !== ""
                            ? personContactName.v
                            : el.personContactName
                        }`}
                      />
                      <TextField
                        fullWidth
                        value={email.i === el._id ? email.v : el.email}
                        onChange={(e) =>
                          setEmail({
                            i: el._id,
                            v: e.nativeEvent.target.value,
                          })
                        }
                        label={`Email: ${
                          email.v && email.v !== "" ? email.v : el.email
                        }`}
                      />
                      <TextField
                        fullWidth
                        value={tel.i === el._id ? tel.v : el.tel}
                        onChange={(e) =>
                          setTel({
                            i: el._id,
                            v: e.nativeEvent.target.value,
                          })
                        }
                        label={`Tel: ${tel.v && tel.v !== "" ? tel.v : el.tel}`}
                      />
                      <Select
                        fullWidth
                        value={
                          category?.i === el._id && category.v !== "n/n"
                            ? category.v
                            : el.category
                        }
                        label="Category"
                        onChange={(e) =>
                          setCategory({
                            i: el._id,
                            v: e.target.value,
                          })
                        }
                      >
                        {serverCategories?.categories &&
                          serverCategories?.categories.map((el, i) => {
                            return (
                              <MenuItem key={i} value={el.name}>
                                {el.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <GooglePlacesAutocomplete
                        selectProps={{
                          placeholder: el.location,
                          place:
                            searchPlace.i === el._id
                              ? searchPlace.v
                              : el.location,
                          onChange: (e) => setSearchPlaceResults(e, el._id),
                        }}
                        apiKey={admin.googleApiKey || "hi"}
                      />
                      <div>
                        Interested
                        <Switch
                          defaultChecked={el.interested ? true : false}
                          value={
                            interested.i === el._id
                              ? interested.v
                              : el.interested
                          }
                          onChange={() =>
                            setInterested({
                              i: el._id,
                              v:
                                interested.v === ""
                                  ? !el.interested
                                  : !interested.v,
                            })
                          }
                        />
                      </div>
                      <div>
                        <h1>Cold Call</h1>
                        <div>
                          <TextField
                            type="number"
                            value={
                              called.i === el._id
                                ? called.v
                                : el?.coldcall?.called
                            }
                            onChange={(e) =>
                              setCalled({
                                i: el._id,
                                v: Number(e.nativeEvent.target.value),
                              })
                            }
                            label="Called"
                          />
                        </div>
                        <div>
                          <TextField
                            label="Reached"
                            type="number"
                            value={
                              reached.i === el._id
                                ? reached.v
                                : el?.coldcall?.reached
                            }
                            onChange={(e) =>
                              setReached({
                                i: el._id,
                                v: Number(e.nativeEvent.target.value),
                              })
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            label="Duration"
                            type="number"
                            value={
                              duration.i === el._id
                                ? duration.v
                                : el?.coldcall?.duration
                            }
                            onChange={function (e) {
                              setDuration({
                                i: el._id,
                                v: Number(e.nativeEvent.target.value),
                              });
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            label="Callback"
                            type="text"
                            value={
                              callback.i === el._id
                                ? callback.v
                                : el?.coldcall?.callback
                            }
                            onChange={(e) =>
                              setCallback({
                                i: el._id,
                                v: e.nativeEvent.target.value,
                              })
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            label="Notes"
                            type="text"
                            value={
                              notes.i === el._id ? notes.v : el?.coldcall?.notes
                            }
                            onChange={(e) =>
                              setNotes({
                                i: el._id,
                                v: e.nativeEvent.target.value,
                              })
                            }
                          />
                        </div>
                        <div>
                          Converted
                          <Switch
                            value={
                              converted.i === el._id
                                ? converted.v
                                : el.coldcall?.converted
                            }
                            defaultChecked={
                              el.coldcall?.converted ? true : false
                            }
                            onChange={() =>
                              setConverted({
                                i: el._id,
                                v:
                                  converted.v === ""
                                    ? !el.coldcall?.converted
                                    : !converted.v,
                              })
                            }
                          />
                        </div>
                        <div>Tracking:{JSON.stringify(el.tracking)}</div>
                        <div>Contacted:{JSON.stringify(el.contacted)}</div>
                        <div>Opened:{JSON.stringify(el.opened)}</div>
                      </div>
                      <Button
                        color="#7CFC00"
                        children="SAVE"
                        onClick={function () {
                          updateSales({
                            id: el._id,
                            coordinates:
                              lat.v !== ""
                                ? [lon.v, lat.v]
                                : el.geometry.coordinates,
                            companyName:
                              companyName.v !== ""
                                ? companyName.v
                                : el.companyName,
                            personContactName:
                              personContactName.v !== ""
                                ? personContactName.v
                                : el.personContactName,
                            email: email.v !== "" ? email.v : el.email,
                            tel: tel.v !== "" ? tel.v : el.tel,
                            category:
                              category.v !== "" ? category.v : el.category,
                            location:
                              location.v !== "" ? location.v : el.location,
                            interested:
                              interested.v !== ""
                                ? interested.v
                                : el.interested !== undefined
                                ? el.interested
                                : undefined,
                            // coldcall starts
                            called:
                              called.v !== ""
                                ? called.v
                                : el.coldcall?.called !== undefined
                                ? el.coldcall?.called
                                : undefined,
                            reached:
                              reached.v !== ""
                                ? reached.v
                                : el.coldcall?.reached !== undefined
                                ? el.coldcall?.reached
                                : undefined,
                            duration:
                              duration.v !== ""
                                ? duration.v
                                : el.coldcall?.duration !== undefined
                                ? el.coldcall?.duration
                                : undefined,
                            callback:
                              callback.v !== ""
                                ? callback.v
                                : el.coldcall?.callback !== undefined
                                ? el.coldcall?.callback
                                : undefined,
                            converted:
                              converted.v !== ""
                                ? converted.v
                                : el.coldcall?.converted !== undefined
                                ? el.coldcall?.converted
                                : undefined,
                            notes:
                              notes.v !== ""
                                ? notes.v
                                : el.coldcall?.notes !== undefined
                                ? el.coldcall?.notes
                                : undefined,
                          });
                        }}
                      />
                      <br></br>
                      <br></br>
                      <br></br>
                      <Button
                        color="#FF0000"
                        children="DELETE Sales PARTNER"
                        onClick={() => deletePartnerSales(el._id)}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
