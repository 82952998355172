import { httpIpPort } from "./httpIpPort";
/**
 * @param {payload} payload
 * @returns requested
 * payload = object. Example: await request ({payloadObject})
 * reqtype
 * endpoint
 * data = object. Example: data : {eMail: "hi@hi.de"}
 */
export async function request(payload) {
  const { reqtype, endpoint, data, noJson, multi } = payload;

  const contentType = multi ? "multipart/form-data" : "application/json";
  const reqData = {
    method: reqtype,
    headers: {
      "Content-Type": contentType,
    },
    body: multi ? data : JSON.stringify(data),
  };

  try {
    if (reqtype === "GET") {
      const result = await fetch(`${httpIpPort}${endpoint}`);
      return await result.json();
    } else if (noJson) {
      return await fetch(`${httpIpPort}${endpoint}`, reqData);
    } else {
      const result = await fetch(`${httpIpPort}${endpoint}`, reqData);
      return await result.json();
    }
  } catch (e) {
    console.log(e, "eerr");
  }
}
