export const colors = {
  blue: '#0D5CF2',
  main: '#0D5CF2',
  gold: '#F09907',
  whiteblue: '#DDE9F4',
  white: '#FFFFFF',
  link: '#0D5CF2',
  bodyText: '#626262',
  grey: '#666666',
  badge: '#DDF8F9',
  illustrationsYellow: '#FFD200',
  turquoise: '#DDF8F9',
};
