import "./App.css";
import Start from "./pages/Start";
import Privacy from "./pages/Privacy";
import Faq from "./pages/Faq";
import Imprint from "./pages/Imprint";
import Overview from "./pages/AdminArea/Overview";
import RegularLogin from "./pages/RegularLogin";
import Footer from "./components/Footer";
import AdminSales from "./pages/AdminArea/AdminSales";
import AdminNewsletter from "./pages/AdminArea/AdminNewsletter";
import AdminUsers from "./pages/AdminArea/AdminUsers";
import AdminEmails from "./pages/AdminArea/AdminEmails";
import { Toaster } from "react-hot-toast";
import { createContext } from "react";
import { Routes, Route } from "react-router-dom";
import AdminCompanies from "./pages/AdminArea/AdminCompanies";
import AdminCreateCompany from "./pages/AdminArea/AdminCreateCompany";
import DeleteUser from "./pages/DeleteUser";

export const Context = createContext("Context");
function App() {
  const admin = JSON.parse(localStorage.getItem("mygreekappadmin"));

  return (
    <Context.Provider
      value={{
        admin,
      }}
    >
      <div className="App">
        <Toaster />
        <div className="App__Body">
          <Routes>
            <Route path="/" element={<Start />} />
            <Route path="*" element={<div>Δέν υπάρχει</div>} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/rl" element={<RegularLogin />} />
            <Route path="/deleteuser" element={<DeleteUser />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/adminsales" element={<AdminSales />} />
            <Route path="/adminnewsletter" element={<AdminNewsletter />} />
            <Route path="/adminsales" element={<AdminSales />} />
            <Route path="/admincompanies" element={<AdminCompanies />} />
            <Route path="/adminemails" element={<AdminEmails />} />
            <Route
              path="/admincreatecompany"
              element={<AdminCreateCompany />}
            />
            <Route path="/adminusers" element={<AdminUsers />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Context.Provider>
  );
}

export default App;
