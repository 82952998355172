import React, { useState, useEffect } from "react";
import { regexCollection } from "../../helpers/regexCollection";
import { request } from "../../helpers/request";
import Loader from "../../components/Loader";
import { TextField, Select, MenuItem } from "@mui/material";
import Button from "../../components/Button";
import Autocomplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import "./AdminCreateCompany.css";

export default function AdminCreateCompany() {
  const [serverCategories, setServerCategories] = useState();
  const admin = JSON.parse(localStorage.getItem("mygreekappadmin"));
  // RENDERING AND STATE
  const [loading, setLoading] = useState(false),
    // GEO //
    [monday, setMonday] = useState(),
    [tuesday, setTuesday] = useState(),
    [wednesday, setWednesday] = useState(),
    [thursday, setThursday] = useState(),
    [friday, setFriday] = useState(),
    [saturday, setSaturday] = useState(),
    [sunday, setSunday] = useState(),
    [latitude, setLatitude] = useState(undefined),
    [longitude, setLongitude] = useState(undefined);

  useEffect(() => {
    async function categoryRequest() {
      const srvCategories = await request({
        reqtype: "GET",
        endpoint: "/categories",
      });

      srvCategories.categories.sort(function (a, b) {
        return a.name.localeCompare(b.name, "el");
      });

      setServerCategories(srvCategories);
    }
    categoryRequest();
  }, []);
  // CATEGORIES //
  const [categories, setCategories] = useState([]);

  // BASIC INFOS //
  const [companyName, setCompanyName] = useState(),
    [email, setEmail] = useState(),
    [phone, setPhone] = useState(),
    [website, setWebsite] = useState(),
    [address, setAddress] = useState(), // coming from component MapSearch
    // SOCIALS //
    [facebook, setFacebook] = useState(),
    [twitter, setTwitter] = useState(),
    [instagram, setInstagram] = useState(),
    [whatsapp, setWhatsapp] = useState(),
    [linkedin, setLinkedin] = useState(),
    // IMAGES //
    [logoImage, setlogoImage] = useState(
      "https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,q_100,w_375/v1681462392/Assets/Screenshot_2023-04-14_at_10.52.06_vjfrzc.jpg"
    ),
    [description, setDescription] = useState();
  // to do: create a component for that

  async function createCompany() {
    let errorArray = [],
      varObject = {
        email,
        instagram,
        twitter,
        whatsapp,
        facebook,
        website,
        linkedin,
        phone,
      };

    const finalChosenCategories = categories.map((obj) => {
      const { name } = obj;
      return name;
    });

    for (const property in varObject) {
      if (
        property === "email" &&
        varObject[property] &&
        !regexCollection.email.test(varObject[property])
      ) {
        errorArray.push({ type: property, error: "Email incorrect" });
      }
      if (
        property === "phone" &&
        varObject[property] &&
        !regexCollection.startWithPlusFollowByNumbers.test(varObject[property])
      ) {
        errorArray.push({ type: property, error: "Wrong format" });
      }
      if (
        property === "website" &&
        varObject[property] &&
        !regexCollection.plainDomainWithDot.test(varObject[property])
      ) {
        errorArray.push({ type: property, error: "Wrong format" });
      }
    }
    if (!companyName || companyName.trim().length === 0) {
      errorArray.push({ type: "Όνομα", error: "cannotbeempty" });
    }
    if (finalChosenCategories.length === 0) {
      errorArray.push({ type: "Κατηγορίες", error: "cannotbeempty" });
    }
    if (!address) {
      errorArray.push({ type: "Διεύθυνση", error: "cannotbeempty" });
    }
    if (!longitude || !latitude) {
      errorArray.push({ type: "Geolocation", error: "cannotbeempty" });
    }

    if (errorArray.length === 0) {
      const openingHoursObject = {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      };
      const socialsObject = {
        website: website ? website.trim() : website,
        instagram: instagram ? instagram.trim() : instagram,
        facebook: facebook ? facebook.trim() : facebook,
        twitter: twitter ? twitter.trim() : twitter,
        linkedin: linkedin ? linkedin.trim() : linkedin,
        whatsapp: whatsapp
          ? whatsapp
              .split("")
              .filter((char) => /^[0-9]*$/.test(char))
              .join("")
          : whatsapp,
      };
      const formData = new FormData();

      if (
        logoImage &&
        logoImage !==
          "https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,q_100,w_375/v1681462392/Assets/Screenshot_2023-04-14_at_10.52.06_vjfrzc.jpg"
      ) {
        formData.append("logo", {
          name: new Date() + "__logo",
          uri: logoImage,
          type: "image/jpg",
        });
      }

      const finalData = {
        email: email ? email.trim() : email,
        name: companyName.trim(),
        address: address,
        phone: phone ? phone.trim() : phone,
        description: description,
        categories: JSON.stringify(finalChosenCategories),
        socials: JSON.stringify(socialsObject),
        openingHours: JSON.stringify(openingHoursObject),
        owner: admin.user.id,
        userId: admin.user.id,
        adminToken: admin.token,
        geometry: {
          type: "Point",
          coordinates: [longitude, latitude],
        },
      };

      try {
        setLoading(true);
        const response = await request({
          reqtype: "POST",
          endpoint: "/api/admin/createcompany",
          data: finalData,
        });

        if (response.status === "CREATED") {
          setLoading(false);
          toast.success("CREATED");
        } else if (response.status === "NO CREDITS") {
          setLoading(false);
          toast.error("No Credits");
          setTimeout(function () {
            navigation.navigate("Overview");
          }, 3000);
        } else if (response.status === "EXISTS") {
          setLoading(false);
          toast.error("Το Όνομα χρησιμοποιείται ήδη καπου αλλού");
        } else if (response.status === "SAME LOCATION") {
          setLoading(false);
          toast.error("Αυτή η διεύθυνση χρησιμοποιείται είδη κάπου αλλού");
        }
      } catch (e) {
        toast.error("Con Prob", e);

        console.log("catch::", e);
        setLoading(false);
      }
    } else {
      toast.error(`type: ${errorArray[0].type} error: ${errorArray[0].error}`);
    }
  }

  async function getLatLong(location) {
    const res = await geocodeByAddress(location);
    const geo = await getLatLng(res[0]);
    setLatitude(geo.lat);
    setLongitude(geo.lng);
    setAddress(location);
  }

  async function handleCategoryChange(cat) {
    setCategories(cat);
  }

  return (
    <div style={{ flex: 1 }}>
      {loading && <Loader />}
      {admin.googleApiKey && (
        <Autocomplete
          style={{ width: "400px", padding: "12px" }}
          apiKey={admin.googleApiKey}
          onPlaceSelected={(place) => getLatLong(place.formatted_address)}
          placeholder="Location"
          options={{
            types: ["geocode", "establishment"],
          }}
        />
      )}

      {
        <div>
          <div>
            <div style={{ alignSelf: "center", marginBottom: 15 }}></div>
            <div style={{ marginTop: 30 }}>
              <p>Βασικές πληροφορίες*</p>
              <TextField
                value={companyName}
                onChange={(e) => setCompanyName(e.nativeEvent.target.value)}
                label="Όνομα"
              />
              <TextField
                value={email}
                onChange={(e) => setEmail(e.nativeEvent.target.value)}
                label="E-Mail"
              />

              <Select
                multiple
                value={categories}
                label="Categories"
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                {serverCategories?.categories &&
                  serverCategories?.categories.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div>
              <p>Βασικές πληροφορίες</p>
              <div className="inputcolumn">
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.nativeEvent.target.value)}
                  label="Περιγραφή"
                />
                <TextField
                  value={phone}
                  onChange={(e) => setPhone(e.nativeEvent.target.value)}
                  label="Τηλέφωνο"
                />
                <TextField
                  value={website}
                  onChange={(e) => setWebsite(e.nativeEvent.target.value)}
                  label="Ιστοσελίδα"
                />
                <TextField
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.nativeEvent.target.value)}
                  label="WhatsApp"
                />
              </div>
            </div>
            <div>
              <p>Socials</p>
              <div className="inputcolumn">
                <TextField
                  value={facebook}
                  onChange={(e) => setFacebook(e.nativeEvent.target.value)}
                  label="Facebook"
                />
                <TextField
                  value={twitter}
                  onChange={(e) => setTwitter(e.nativeEvent.target.value)}
                  label="Twitter"
                />
                <TextField
                  value={instagram}
                  onChange={(e) => setInstagram(e.nativeEvent.target.value)}
                  label="Instagram"
                />
                <TextField
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.nativeEvent.target.value)}
                  label="LinkedIn"
                />
              </div>
            </div>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <p>Ώρες λειτουργείας</p>
              <div className="inputcolumn">
                <TextField
                  value={monday}
                  onChange={(e) => setMonday(e.nativeEvent.target.value)}
                  label="Δευτέρα"
                />
                <TextField
                  value={tuesday}
                  onChange={(e) => setTuesday(e.nativeEvent.target.value)}
                  label="Τρίτη"
                />
                <TextField
                  value={wednesday}
                  onChange={(e) => setWednesday(e.nativeEvent.target.value)}
                  label="Τετάρτη"
                />
                <TextField
                  value={thursday}
                  onChange={(e) => setThursday(e.nativeEvent.target.value)}
                  label="Πέμπτη"
                />
                <TextField
                  value={friday}
                  onChange={(e) => setFriday(e.nativeEvent.target.value)}
                  label="Παρασκευή"
                />
                <TextField
                  value={saturday}
                  onChange={(e) => setSaturday(e.nativeEvent.target.value)}
                  label="Σάββατο"
                />
                <TextField
                  value={sunday}
                  onChange={(e) => setSunday(e.nativeEvent.target.value)}
                  label="Κυριακή"
                />
              </div>
            </div>
            <Button onClick={() => createCompany()}>Save</Button>
          </div>
        </div>
      }
    </div>
  );
}
