import Button from "../../components/Button";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../App";
import { request } from "../../helpers/request";
import { colors } from "../../helpers/maincolors";
import { TextField, Select, MenuItem } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";

export default function AdminCompanies() {
  const { admin } = useContext(Context);
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [showEditContainer, setShowEditContainer] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  // start company relevant
  const [name, setName] = useState({ v: undefined, i: undefined });
  const [address, setAddress] = useState({ v: undefined, i: undefined });
  const [email, setEmail] = useState({ v: undefined, i: undefined });
  const [description, setDescription] = useState({
    v: undefined,
    i: undefined,
  });
  const [categories, setCategories] = useState("n/n");
  const [phone, setPhone] = useState({ v: undefined, i: undefined });
  const [serverCategories, setServerCategories] = useState();
  const [geometry, setGeometry] = useState({ v: undefined, i: undefined });
  const [socials, setSocials] = useState({ v: undefined, i: undefined });

  async function categoryRequest() {
    const srvCategories = await request({
      reqtype: "GET",
      endpoint: "/categories",
    });

    srvCategories.categories.sort(function (a, b) {
      return a.name.localeCompare(b.name, "el");
    });

    setServerCategories(srvCategories);
  }

  async function handleArrowClick(action) {
    try {
      const comanyResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/getallcompanies",
        data: {
          page: action === "previous" ? page - 1 : page + 1,
          userId: admin.user.id,
          ...(admin.token && { adminToken: admin.token }),
        },
      });

      action === "previous" ? setPage(page - 1) : setPage(page + 1);
      setCompanies(comanyResult.companies);
    } catch (e) {}
  }
  async function getCompanies() {
    try {
      const comanyResult = await request({
        reqtype: "POST",
        endpoint: "/api/admin/getallcompanies",
        data: {
          page,
          userId: admin.user.id,
          ...(admin.token && { adminToken: admin.token }),
        },
      });
      setMaxPage(comanyResult.maxPage);
      setCompanies(comanyResult.companies);
    } catch (e) {}
  }
  async function setSearchPlaceResults(e, i) {
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (i) {
          setGeometry({
            v: { type: "Point", coordinates: [lng, lat] },
            i,
          });
          setAddress({ i, v: e.value.structured_formatting.main_text });
        }
      });
  }

  async function updateCompany(company) {
    const updatedFields = {};
    const include = [
      "name",
      "geometry",
      "categories",
      "address",
      "email",
      "phone",
      "description",
      "socials",
    ];
    include.forEach((key) => {
      if (
        eval(key).v !== undefined &&
        company[key] !== eval(key)?.v &&
        eval(key).i === company._id
      ) {
        updatedFields[key] = eval(key)?.v;
      }
    });
    if (Object.keys(updatedFields).length > 0) {
      const companyResult = await request({
        reqtype: "PATCH",
        endpoint: "/api/admin/updatecompany",
        data: {
          updatedFields: updatedFields,
          companyId: company._id,
          userId: admin.user.id,
          ...(admin.token && { adminToken: admin.token }),
        },
      });
      if (companyResult.status === "updated") {
        toast.success("Updated");
      }
    } else {
      toast.error("Nothing Updated - no request");
    }
  }

  useEffect(() => {
    getCompanies();
    categoryRequest();
  }, []);

  return companies.length > 0 ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: 40,
        }}
      >
        {maxPage > page && (
          <Button
            color="#000000"
            children="->"
            onClick={() => handleArrowClick("next")}
          />
        )}
        {page > 1 && (
          <Button
            color="#000000"
            children="<-"
            onClick={() => handleArrowClick("previous")}
          />
        )}
      </div>
      <TextField
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.nativeEvent.target.value)}
        label="Search"
      />

      {companies.map((el, i) => {
        if (el.name.toUpperCase().includes(searchQuery.toUpperCase())) {
          return (
            <div key={i}>
              <div
                style={{
                  padding: 5,
                  marginBottom: 10,
                }}
              >
                <Button
                  color={
                    el.subscription.rniap.productId === "admin"
                      ? "#666666"
                      : colors.blue
                  }
                  children={el.name}
                  onClick={() => {
                    if (showEditContainer === el.name) {
                      setShowEditContainer(undefined);
                    } else {
                      setShowEditContainer(el.name);
                    }
                  }}
                />
              </div>
              {showEditContainer === el.name && (
                <div>
                  <TextField
                    fullWidth
                    value={name.i === el._id ? name.v : el.name || ""}
                    onChange={(e) =>
                      setName({
                        i: el._id,
                        v: e.nativeEvent.target.value,
                      })
                    }
                    label={`Company Name: ${
                      name.i === el._id ? name.v : el.name || ""
                    }`}
                  />
                  <GooglePlacesAutocomplete
                    selectProps={{
                      placeholder: el.address,
                      place: address.i === el._id ? address.v : el.address,
                      onChange: (e) => setSearchPlaceResults(e, el._id),
                    }}
                    apiKey={admin.googleApiKey || "hi"}
                  />
                  <Select
                    multiple
                    fullWidth
                    value={
                      categories?.i === el._id && categories.v !== "n/n"
                        ? categories.v
                        : el.categories || ""
                    }
                    label="Categories"
                    onChange={(e) =>
                      setCategories({
                        i: el._id,
                        v: e.target.value,
                      })
                    }
                  >
                    {serverCategories?.categories &&
                      serverCategories?.categories.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el.name}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <TextField
                    fullWidth
                    value={email.i === el._id ? email.v : el.email || ""}
                    onChange={(e) =>
                      setEmail({
                        i: el._id,
                        v: e.nativeEvent.target.value,
                      })
                    }
                    label={`Email: ${
                      email.i === el._id ? email.v : el.email || ""
                    }`}
                  />
                  <TextField
                    fullWidth
                    value={phone.i === el._id ? phone.v : el.phone || ""}
                    onChange={(e) =>
                      setPhone({
                        i: el._id,
                        v: e.nativeEvent.target.value,
                      })
                    }
                    label={`Phone: ${
                      phone.i === el._id ? phone.v : el.phone || ""
                    }`}
                  />
                  <TextField
                    fullWidth
                    value={
                      description.i === el._id
                        ? description.v
                        : el.description || ""
                    }
                    onChange={(e) =>
                      setDescription({
                        i: el._id,
                        v: e.nativeEvent.target.value,
                      })
                    }
                    label={`Description: ${
                      description.i === el._id
                        ? description.v
                        : el.description || ""
                    }`}
                  />
                  <h2>Socials</h2>
                  <TextField
                    fullWidth
                    name="facebook"
                    value={
                      socials.i === el._id && socials?.v?.facebook !== undefined
                        ? socials.v.facebook
                        : el?.socials?.facebook || ""
                    }
                    onChange={(e) =>
                      setSocials((prevState) => ({
                        i: el._id,
                        v: {
                          ...prevState.v,
                          [e.nativeEvent.target.name]:
                            e.nativeEvent.target.value,
                        },
                      }))
                    }
                    label={`facebook: ${
                      socials.i === el._id && socials?.v?.facebook !== undefined
                        ? socials.v.facebook
                        : el?.socials?.facebook || ""
                    }`}
                  />
                  <TextField
                    fullWidth
                    name="instagram"
                    value={
                      socials.i === el._id &&
                      socials?.v?.instagram !== undefined
                        ? socials.v.instagram
                        : el?.socials?.instagram || ""
                    }
                    onChange={(e) =>
                      setSocials((prevState) => ({
                        i: el._id,
                        v: {
                          ...prevState.v,
                          [e.nativeEvent.target.name]:
                            e.nativeEvent.target.value,
                        },
                      }))
                    }
                    label={`instagram: ${
                      socials.i === el._id &&
                      socials?.v?.instagram !== undefined
                        ? socials.v.instagram
                        : el?.socials?.instagram || ""
                    }`}
                  />
                  <TextField
                    fullWidth
                    name="twitter"
                    value={
                      socials.i === el._id && socials?.v?.twitter !== undefined
                        ? socials.v.twitter
                        : el?.socials?.twitter || ""
                    }
                    onChange={(e) =>
                      setSocials((prevState) => ({
                        i: el._id,
                        v: {
                          ...prevState.v,
                          [e.nativeEvent.target.name]:
                            e.nativeEvent.target.value,
                        },
                      }))
                    }
                    label={`twitter: ${
                      socials.i === el._id && socials?.v?.twitter !== undefined
                        ? socials.v.twitter
                        : el?.socials?.twitter || ""
                    }`}
                  />
                  <TextField
                    fullWidth
                    name="whatsapp"
                    value={
                      socials.i === el._id && socials?.v?.whatsapp !== undefined
                        ? socials.v.whatsapp
                        : el?.socials?.whatsapp || ""
                    }
                    onChange={(e) =>
                      setSocials((prevState) => ({
                        i: el._id,
                        v: {
                          ...prevState.v,
                          [e.nativeEvent.target.name]:
                            e.nativeEvent.target.value,
                        },
                      }))
                    }
                    label={`whatsapp: ${
                      socials.i === el._id && socials?.v?.whatsapp !== undefined
                        ? socials.v.whatsapp
                        : el?.socials?.whatsapp || ""
                    }`}
                  />
                  <TextField
                    fullWidth
                    name="linkedin"
                    value={
                      socials.i === el._id && socials?.v?.linkedin !== undefined
                        ? socials.v.linkedin
                        : el?.socials?.linkedin || ""
                    }
                    onChange={(e) =>
                      setSocials((prevState) => ({
                        i: el._id,
                        v: {
                          ...prevState.v,
                          [e.nativeEvent.target.name]:
                            e.nativeEvent.target.value,
                        },
                      }))
                    }
                    label={`linkedin: ${
                      socials.i === el._id && socials?.v?.linkedin !== undefined
                        ? socials.v.linkedin
                        : el?.socials?.linkedin || ""
                    }`}
                  />
                  <div>OpeningHours: {JSON.stringify(el.openingHours)}</div>
                  <div>
                    Subscribed At:
                    {new Date(
                      Number(
                        el.subscription.validatorPayload.payload.startTimeMillis
                      )
                    ).toString()}
                  </div>
                  <div>
                    Expires At:
                    {new Date(
                      Number(
                        el.subscription.validatorPayload.payload
                          .expiryTimeMillis
                      )
                    ).toString()}
                    <br></br>
                    <Button
                      color="#7CFC00"
                      children="SAVE"
                      onClick={() => updateCompany(el)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }
      })}
    </div>
  ) : (
    <Loader />
  );
}
