import { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import { request } from "../../helpers/request";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { Chart, registerables } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
Chart.register(...registerables);

import "./Overview.css";
export default function Overview() {
  const navigate = useNavigate();
  const [createdDate, setCreatedDate] = useState();
  const [globalData, setGlobalData] = useState();
  const [graphData, setGraphData] = useState();
  const [fullData, setFullData] = useState();

  const pages = [
    "companies",
    "createcompany",
    "newsletter",
    "emails",
    "sales",
    "users",
  ];
  const { admin } = useContext(Context);

  async function campaignTracking() {
    const campaignTrackingData = await request({
      reqtype: "POST",
      endpoint: "/api/admin/tracking/graph",
      data: {
        userId: admin.user.id,
        adminToken: admin.token,
      },
    });

    setGlobalData(campaignTrackingData);

    const countArray = campaignTrackingData.map((item) =>
      item.counts.map((el) => {
        return el.count;
      })
    );
    setGraphData(countArray);

    const createdAt = campaignTrackingData[0].counts.map(
      (item) => item.created_at
    );
    setCreatedDate(createdAt); // OK
  }

  useEffect(() => {
    campaignTracking();
  }, []);

  useEffect(() => {
    if (globalData && createdDate) {
      const datasets = globalData.map((el, id) => {
        return {
          id,
          label: el.campaign,
          data: graphData[id],
        };
      });
      setFullData({
        labels: createdDate,
        datasets,
      });
    }
  }, [createdDate, globalData]);

  const options = {
    responsive: true,
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  };

  return admin?.user?.admin ? (
    <div className="Overview">
      <p>hello {admin.user.firstName}</p>
      <div className="Overview__buttons">
        {pages.map((el, i) => {
          return (
            <Button key={i} onClick={() => navigate(`/admin${el}`)}>
              {el}
            </Button>
          );
        })}
      </div>
      {options && fullData && globalData ? (
        <div className="tracking-graph">
          {<Line data={fullData} options={options} />}
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div>
      <div>No Admin - Token Expired?</div>
      <Button children="Login" onClick={() => navigate("/rl")} />
    </div>
  );
}
