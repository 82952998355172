import "./Privacy.css";

export default function Privacy() {
  return (
    <div className="Privacy">
      <main>
        <div id="main">
          <h2>Όροι και Προϋποθέσεις - MyGreekApp</h2>
          <section className="static-content">
            <div className="wrap">
              <div className="static-content-left">
                <div>
                  <ol>
                    <li>
                      <a href="#1">Χρήση του MyGreekApp</a>
                    </li>
                    <li>
                      <a href="#2">
                        Κανόνες για την ανάρτηση αγγελιών (‘'Κανόνες
                        δημοσίευσης’)
                      </a>
                      <ol>
                        <li>
                          2.1 <a href="#21">Κοινοί κανόνες</a>
                        </li>
                        <li>
                          2.2 <a href="#23">Απαγορευμένες αναρτήσεις</a>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <a href="#3">Κατάχρηση του MyGreekApp</a>
                    </li>
                    <li>
                      <a href="#4">Χρεώσεις και υπηρεσίες</a>
                    </li>
                    <li>
                      <a href="#5">Περιεχόμενο</a>
                    </li>
                    <li>
                      <a href="#6">Παράβαση</a>
                    </li>
                    <li>
                      <a href="#7">Ευθύνη</a>
                    </li>
                    <li>
                      <a href="#8">Απαλλαγή</a>
                    </li>
                    <li>
                      <a href="#9">Δικαιώματα τρίτων</a>
                    </li>
                    <li>
                      <a href="#10">Προσωπικά Δεδομένα</a>
                    </li>
                    <li>
                      <a href="#11">Επίλυση διαφορών</a>
                    </li>
                    <li>
                      <a href="#12">Γενικά</a>
                    </li>
                    <li>
                      <a href="#13">Όροι χρήσης από κινητές συσκευές</a>
                      <ul>
                        <li>
                          13.1 <a href="#131">iOS - Apple</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#14">
                        Πολιτική επιστροφής χρημάτων και ακύρωσης υπηρεσιών
                      </a>
                    </li>
                    <li>
                      <a href="#15">Πολιτική Απορρήτου</a>
                      <ul>
                        <li>
                          15.1 <a href="#151">Πεδίο δράσης και Συναίνεση</a>
                        </li>
                        <li>
                          15.2 <a href="#152">Προσωπικά Δεδομένα</a>
                        </li>
                        <li>
                          15.3{" "}
                          <a href="#153">Αλλαγές στην παρούσα ειδοποίηση</a>
                        </li>
                        <li>
                          15.4 <a href="#154">Συλλογή</a>
                        </li>
                        <li>
                          15.5 <a href="#155">Χρήση και διατήρηση</a>
                        </li>
                        <li>
                          15.6 <a href="#156">Ανάκληση συγκατάθεσης</a>
                        </li>
                        <li>
                          15.7
                          <a href="#157">
                            Διεθνής Διαβίβαση προσωπικών δεδομένων
                          </a>
                        </li>
                        <li>
                          15.8 <a href="#158">Μέτρα Ασφαλείας</a>
                        </li>
                        <li>
                          15.9 <a href="#159">Άσκηση των Δικαιωμάτων σας</a>
                        </li>
                        <li>
                          15.10{" "}
                          <a href="#1510">Αλλαγές στην Πολιτική Απορρήτου</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#16">Πολιτική Cookies</a>
                    </li>
                  </ol>
                </div>
                <div>
                  Η εφαρμογή MyGreekApp ανήκει και είναι υπό τη διαχείριση της
                  ατομικής εταιρείας <br />
                  "Michail Mangasarov"
                  <br />
                  με έδρα στην Γερμανία (Cimbernstraße 13a, 81377 Munich)
                  <br />
                  Ust-IdNr.: DE329284979 <br />
                  Επικοινωνία: <br />
                  Τηλέφωνο: +4917656763760
                  <br />
                  E-Mail: info"παπακι"mygreekapp.com
                </div>
                <h2>Όροι και Προϋποθέσεις MyGreekApp</h2>

                <p>
                  Η χρήση του παρόντος διαδικτυακού τόπου διέπεται από τους
                  παρόντες όρους και προϋποθέσεις χρήσης. Καλείστε να τους
                  μελετήσετε προσεκτικά, προτού τους αποδεχτείτε, ενώ σε
                  περίπτωση μη συμφωνίας με αυτούς οφείλετε να μην προβείτε σε
                  χρήση του εν λόγω διαδικτυακού τόπου.
                </p>

                <p>
                  Με την πρόσβαση στην παρόν διαδικτυακη εφαρμογή για κινητά του
                  MyGreekApp (κατάστημα Apple και το Google Play) (εφεξής η
                  "Εφαρμογή MyGreekApp"), συμφωνείτε με τους παρόντες Όρους και
                  Προϋποθέσεις και δεσμεύεστε από αυτούς, οι οποίοι έχουν
                  σχεδιαστεί για να διασφαλίσουν ότι το MyGreekApp λειτουργεί
                  για όλους. Καθώς δύναται να λάβει τροποποιήσει των παρόντων
                  όρων και προϋποθέσεων ανά πάσα στιγμή, συμβουλεύεστε να τους
                  διαβάζετε ανά τακτά χρονικά διαστήματα.
                </p>

                <p>
                  Συγκεκριμένα, σε περίπτωση αποδοχής των όρων, συμφωνείτε
                  ανεπιφύλακτα πως θα συμμορφώνεστε με αυτούς. Σε κάθε
                  περίπτωση, η παραμονή σας στον εν λόγω διαδικτυακό τόπο είτε
                  συνοδεύεται από αξιοποίηση οποιασδήποτε πληροφορίας παρέχεται
                  σε αυτήν είτε όχι, συνεπάγεται την ανεπιφύλακτη αποδοχή των
                  όρων και των προϋποθέσεων που ακολουθούν. Οι όροι και
                  προϋποθέσεις χρήσης που ακολουθούν εφαρμόζονται στο σύνολο της
                  παρόντος εφαρμογης.
                </p>

                <p>
                  Περαιτέρω, με τη δημιουργία λογαριασμού, εγγραφή ως μέλους στο
                  MyGreekApp με την επίσκεψη, την πρόσβαση ή οποιαδήποτε χρήση
                  αυτού αποδέχεστε ανεπιφύλακτα τους παρόντες Όρους και
                  Προϋποθέσεις.
                </p>

                <p>
                  Οι παρόντες Όροι και Προϋποθέσεις αποτελούν νομικά δεσμευτική
                  συμφωνία μεταξύ εσάς και της Γερμανικής ατομικής εταιρείας
                  Michail Mangasarov ( εφεξής "η Εταιρεία", “MyGreekApp”,). Η
                  Εταιρεία είναι δεόντως καταχωρημένη σύμφωνα με τις διατάξεις
                  του περί εταιρειών νόμου και είναι ο ιδιοκτήτης του
                  MyGreekApp.
                </p>

                <p>
                  Οι παρόντες Όροι και Προϋποθέσεις αποτελούν το
                  σύνολο/περιεχόμενο της συμφωνίας μεταξύ της Εταιρείας και εσάς
                  (εφεξής η "συμφωνία"), αντικαθιστώντας τυχόν προηγούμενες
                  συμφωνίες. Από την παρούσα συμφωνία, δεν προβλέπεται ή
                  δημιουργείται κανένας οργανισμός, εταιρική σχέση, κοινοπραξία,
                  σχέση υπαλλήλου - εργοδότη ή δικαιοπάροχου, παρά μόνο η
                  υποχρέωση συμμόρφωσης από μέρους σας με το περιεχόμενο της
                  συμφωνίας.
                </p>

                <h3>Ορισμοί</h3>

                <ul>
                  <li>
                    Ως "Πωλητής" ορίζεται το φυσικό ή νομικό πρόσωπο που
                    δημιουργεί λογαριασμό στο MyGreekApp με σκοπό την δημοσίευση
                    αγγελιών για την πώληση Αντικειμένων ή παροχή υπηρεσιών.
                  </li>
                  <li>
                    Ως "Αγοραστής" ορίζεται φυσικό ή νομικό πρόσωπο που είτε
                    έχοντας δημιουργήσει, είτε μη έχοντας δημιουργήσει
                    λογαριασμό στο MyGreekApp, επισκέπτεται ή και κάνει χρήση
                    της Εφαρμογής με την παροχή υπηρεσιών.
                  </li>
                  <li>
                    Ως «Χρήστης» ορίζεται οποιοσδήποτε είτε απλώς επισκέπτεται ή
                    και κάνει χρήση της εφαρμογή για οποιονδήποτε σκοπό,
                    συμπεριλαμβανομένων των Πωλητών και των Αγοραστών (και
                    ερμηνεύεται ανάλογα με το περιεχόμενο της κάθε παραγράφου).
                    Για τους σκοπούς της παραγράφου 2.2. Χρήστης σημαίνει τον
                    ιδιοκτήτη ακινήτων, εργολάβοι, μεσίτες, νόμιμοι εκπρόσωποι
                    των ιδιοκτητών των ακινήτων με σχετική εξουσιοδότηση και
                    συγγενείς πρώτου βαθμού των ιδιοκτητών των ακινήτων.
                  </li>
                  <li>
                    Ως "Αγγελία" νοείται οποιαδήποτε αγγελία αναρτάται στο
                    MyGreekApp.
                  </li>
                  <li>
                    Στη συνέχεια, η λέξη "εμείς" αναφέρεται στο MyGreekApp, την
                    Εταιρεία, τους ιδιοκτήτες της Εταιρείας, τη διοίκηση και το
                    προσωπικό της Εταιρείας.
                  </li>
                </ul>

                <h2>
                  <a id="1" name="1">
                    01. Χρήση του MyGreekApp
                  </a>
                </h2>
                <p>
                  Επιπλέον με τη χρήση ή πρόσβαση στο MyGreekApp συμφωνείτε ότι
                  δεν θα:
                </p>

                <ul>
                  <li>Παραβείτε κανένα νόμο</li>
                  <li>Παραβείτε τους κανονισμούς ανάρτησης</li>
                  <li>
                    Δημοσιεύσετε απειλητικό, υβριστικό, δυσφημιστικό, άσεμνο ή
                    ανάρμοστο υλικό
                  </li>
                  <li>
                    Δημοσιεύσετε ή κοινοποιήσετε οποιοδήποτε ψευδές, ανακριβές ή
                    παραπλανητικό υλικό
                  </li>

                  <li>Παραβιάσετε οποιοδήποτε δικαίωμα τρίτου</li>
                  <li>
                    Διανείμετε ιούς ή οποιαδήποτε άλλη τεχνολογία που μπορεί να
                    βλάψει την εφαρμογή ή τα συμφέροντα ή την ιδιοκτησία των
                    χρηστών MyGreekApp
                  </li>
                  <li>
                    Eπιβάλλετε, ούτε θα συμβάλλετε στην επιβολή ενός
                    αδικαιολόγητου ή μεγάλου φόρτου στην υποδομή μας, ούτε θα
                    παρέμβετε στη σωστή λειτουργία του MyGreekApp
                  </li>
                  <li>
                    Αντιγράψετε, τροποποιήσετε ή διανείμετε το περιεχόμενο
                    κάποιου άλλου ατόμου χωρίς τη συγκατάθεσή του
                  </li>
                  <li>
                    Αντιγράψετε, τροποποιήσετε, διανείμετε δικαιώματα ή
                    περιεχόμενο από το MyGreekApp ή τα πνευματικά δικαιώματα και
                    τα εμπορικά σήματα του MyGreekApp
                  </li>
                  <li>
                    Συγκεντρώσετε ή συλλέξετε με άλλον τρόπο πληροφορίες για
                    τους χρήστες, συμπεριλαμβανομένων των διευθύνσεων
                    ηλεκτρονικού ταχυδρομείου, χωρίς τη συγκατάθεσή τους
                  </li>
                  <li>
                    Παρακάμψετε τα μέτρα που χρησιμοποιούνται για την πρόληψη ή
                    τον περιορισμό της πρόσβασης στο MyGreekApp
                  </li>
                </ul>

                <ul>
                  <li>
                    Συγκατατίθενται και αποδέχονται ότι το MyGreekApp δε φέρει
                    ουδεμία ευθύνη για το περιεχόμενο των αναρτήσεων.
                    Αποκλειστική ευθύνη, είτε ποινικής είτε οποιασδήποτε άλλης
                    φύσης, φέρει αποκλειστικά το πρόσωπο το οποίο προβαίνει στην
                    ανάρτηση
                  </li>
                  <li>
                    Συγκατατίθενται και δηλώνουν ότι οι φωτογραφίες που
                    αναρτώνται και οι υπηρεσίες που διαφημίζονται αφορούν το
                    πρόσωπο το οποίο προβαίνει στην ανάρτηση
                  </li>
                  <li>
                    Συγκατατίθενται και δηλώνουν ότι οι αναρτήσεις στην εν λόγω
                    ενότητα γίνονται με τη δική τους ελεύθερη βούληση και δεν
                    είναι προϊόν πίεσης ή άλλως πως από τρίτα πρόσωπα
                  </li>
                  <li>
                    Συγκατατίθενται και αποδέχονται ότι δεν θα αναρτούν
                    φωτογραφίες ατόμων ηλικίας κάτω των 18 ετών και φωτογραφίες
                    παιδιών
                  </li>
                  <li>
                    Δεν προάγουν και δεν συμμετέχουν σε οποιαδήποτε παράνομη
                    πράξη η οποία αποτελεί ποινικό ή άλλο αδίκημα στη βάση των
                    νόμων του νομικού συστήματος της χώρας που βρίσκεται ο
                    χρήστης.
                  </li>
                </ul>

                <p>
                  Είστε αποκλειστικά υπεύθυνοι για όλες τις πληροφορίες που
                  υποβάλλετε στο MyGreekApp και για τυχόν συνέπειες που μπορεί
                  να προκύψουν από την ανάρτηση σας, περιλαμβανομένης πιθανής
                  ποινικής δίωξης από τις αρμόδιες αρχές.
                </p>

                <p>
                  Διατηρούμε το δικαίωμα κατά τη διακριτική μας ευχέρεια να
                  αρνηθούμε ή να διαγράψουμε περιεχόμενο το οποίο θεωρούμε
                  ακατάλληλο ή παραβιάζει τους παρόντες Όρους και Προϋποθέσεις.
                  Διατηρούμε επίσης το δικαίωμα κατά την κρίση μας να
                  περιορίσουμε προσωρινά ή μόνιμα τη χρήση του MyGreekApp από
                  τον χρήστη ή να αρνηθούμε την εγγραφή ενός χρήστη. Στην
                  περίπτωση που υπάρχουν ενδείξεις ότι παραβιάζετε τους παρόντες
                  Όρους και Προϋποθέσεις με οποιονδήποτε τρόπο και/ή
                  συμπεριφέρεστε ύποπτα στο MyGreekApp, έχουμε τη δυνατότητα,
                  κατά την κρίση μας, να λάβουμε τα μέτρα που είναι κατά τη
                  κρίση μας αναγκαία και κατάλληλα και να ενημερώσουμε άλλους
                  χρήστες MyGreekApp που έχουν έρθει σε επαφή μαζί σας και να
                  τους συστήσουμε να είναι επιφυλακτικοί.
                </p>

                <p>
                  Στην περίπτωση που λογαριασμοί είναι μη επιβεβαιωμένοι ή είναι
                  ανενεργοί για μεγάλο χρονικό διάστημα, διατηρούμε το δικαίωμα,
                  κατά τη κρίση μας, να τους ακυρώνουμε. Παράλληλα, διατηρούμε
                  το δικαίωμα να αρνηθούμε, να τροποποιήσουμε ή να τερματίσουμε
                  όλες ή μέρος των Υπηρεσιών μας σε οποιονδήποτε για
                  οποιονδήποτε λόγο κατά την κρίση μας, προκειμένου να
                  διασφαλίσουμε την εί δυνατόν άριστη λειτουργία του
                  συγκεκριμένου διαδικτυακού τόπου.
                </p>

                <h2>
                  <a name="2">
                    02. Κανόνες για την ανάρτηση αγγελιών ('Κανόνες δημοσίευσης)
                  </a>
                </h2>

                <p>
                  Το MyGreekApp είναι μια ανοιχτή πλατφόρμα όπου οι χρήστες
                  μπορούν να διαφημίσουν τις επιχειρήσεις και υπηρεσίες τους,
                  υπό τον όρο ότι αυτό συμβαίνει εντός λογικού πλαισίου, σύμφωνα
                  με τυχόν νομικούς περιορισμούς και δεν αντιβαίνουν στους
                  νομικούς περιορισμούς που διέπουν στην χώρα που βρίσκεται ο
                  χρήστης.
                </p>

                <p>
                  Το MyGreekApp προσπαθεί να διασφαλίσει ότι όλες οι πληροφορίες
                  που παρέχονται μέσω των αγγελιών είναι σωστές κατά τη στιγμή
                  της καταχώρισης, αλλά δεν είναι αρμόδιο και άρα δεν έχει
                  ευθύνη να εγγυηθεί ότι οι πληροφορίες που περιέχονται είναι
                  ακριβείς, ολοκληρωμένες, επαληθευμένες ή πλήρεις
                </p>

                <p>
                  Ενώ έχει ληφθεί μέριμνα για την προετοιμασία του, το
                  MyGreekApp δεν παρέχει καμία εγγύηση ή παρατήρηση σχετικά με
                  την ακρίβεια ή την αξιοπιστία του. Δεν δεχόμαστε ούτε
                  υποθέτουμε καμία αξιοπιστία σε σχέση με το περιεχόμενο αυτών
                  των σελίδων, το οποίο δεν πρέπει να θεωρείται ακριβές. Οι
                  σελίδες που περιέχονται στην εφαρμογή μπορεί να περιέχουν
                  τεχνικές ανακρίβειες και τυπογραφικά λάθη. Οι πληροφορίες σε
                  αυτές τις σελίδες ενδέχεται να ενημερώνονται από καιρό σε
                  καιρό και μπορεί κατά καιρούς να μην είναι ενημερωμένες. Δεν
                  δεχόμαστε καμία ευθύνη για την ενημέρωση των πληροφοριών σε
                  αυτές τις σελίδες ούτε για τυχόν παράλειψη.
                </p>

                <p>
                  Οι χρήστες είναι αποκλειστικά υπεύθυνοι για την αλήθεια και
                  την ακρίβεια των πληροφοριών που δημοσιεύουν στο MyGreekApp σε
                  σχέση με τα ακόλουθα:
                </p>

                <ul>
                  <li>
                    τα προσωπικά τους στοιχεία (συμπεριλαμβανομένου του ονόματός
                    τους, ηλικίας, επαγγέλματος και στοιχείων επικοινωνίας)
                  </li>
                  <li>
                    πληροφορίες σχετικά με την υπηρεσία που δημοσιεύουν,
                    προωθούν και/ή διαφημίζουν
                  </li>
                  <li>
                    τηρώντας τους νόμους και κανονισμούς της Χώρας που
                    βρίσκονται όσον αφορά το διαφήμισης, προώθησης και/ή να
                    ενεργούν ως πράκτορες σε σχέση με τα αντικείμενα.
                  </li>
                </ul>

                <p>
                  Οι χρήστες είναι αποκλειστικά υπεύθυνοι για τυχόν συνέπειες
                  που μπορεί να προκύψουν από την αγγελία τους. Διατηρούμε το
                  δικαίωμα να αρνηθούμε ή να διαγράψουμε ή να επεξεργαστούμε
                  αγγελίες που πιστεύουμε ότι είναι ή θεωρούνται ακατάλληλες για
                  ανάρτηση στο MyGreekApp.
                </p>

                <p>
                  Το MyGreekApp δεν επιτρέπει τη χρήση του ονόματος ή του
                  λογότυπου MyGreekApp από τρίτους χωρίς έγγραφη άδεια χρήσης.
                  Δεν επιτρέπεται η χρήση του ονόματος ή του λογοτύπου
                  MyGreekApp σε οποιοδήποτε διαφημιστικό υλικό, φυλλάδια ή
                  άλλους ιστότοπους χωρίς την προαναφερθείσα συμφωνία.
                </p>

                <p>
                  Όλες οι πληροφορίες και τα υλικά που εμφανίζονται στο
                  MyGreekApp προστατεύονται από πνευματικά δικαιώματα και
                  αποτελούν ιδιοκτησία του MyGreekApp. Δεν μπορείτε να
                  αντιγράφετε, να αναπαράγετε, να διανέμετε, να δημοσιεύετε, να
                  δημιουργείτε παράγωγα έργα, να αποθηκεύετε ηλεκτρονικά, να
                  μεταδίδετε ή να εκμεταλλεύεστε με οποιονδήποτε τρόπο για
                  εμπορικά κέρδη ή με οποιονδήποτε άλλο τρόπο, οποιοδήποτε
                  περιεχόμενο ή αναρτημένες πληροφορίες στον Ιστότοπο, εκτός από
                  το δικό σας περιεχόμενο, το οποίου είστε κύριος ή/και έχετε
                  την εξουσία διάθεσης του.
                </p>

                <p>
                  Όλο το περιεχόμενο που εμφανίζεται στο MyGreekApp
                  προστατεύεται από πνευματικά δικαιώματα, εμπορικά σήματα, βάση
                  δεδομένων και άλλα πνευματικά δικαιώματα ιδιοκτησίας.
                </p>

                <p>
                  Σε καμία περίπτωση δεν αναλαμβάνουμε ευθύνη για την περίπτωση
                  που οποιοδήποτε πρόσωπο εμπιστεύτηκε λανθασμένες πληροφορίες
                  που περιέχονται στο MyGreekApp.
                </p>

                <p>
                  Σε καμία περίπτωση, συμπεριλαμβανομένης (χωρίς περιορισμό) της
                  αμέλειας, η Εταιρεία, οι ιδιοκτήτες της Εταιρείας, η διεύθυνση
                  και το προσωπικό της MyGreekApp δεν θα ευθύνονται:
                </p>

                <ul>
                  <li>
                    για ψευδείς ή ανακριβείς πληροφορίες που δημοσιεύθηκαν στο
                    MyGreekApp, που διαφημίζονται στο MyGreekApp ή παρέχονται με
                    άλλο τρόπο στο MyGreekApp και στους αντιπροσώπους του από
                    τους χρήστες.
                  </li>
                  <li>
                    για οποιαδήποτε απώλεια ή βλάβη οποιουδήποτε είδους,
                    συμπεριλαμβανομένων (χωρίς περιορισμό) οποιεσδήποτε άμεσες,
                    ειδικές έμμεσες ή επακόλουθες ζημίες που προκύπτουν από ή σε
                    σχέση με την πρόσβαση, χρήση, απόδοση, περιήγηση ή σύνδεση
                    με το MyGreekApp ή/και μέσω του MyGreekApp.
                  </li>
                </ul>

                <p>
                  Σε καμία περίπτωση δεν αναλαμβάνουμε ευθύνη για την περιγραφή
                  σε ανάρτηση οποιασδήποτε παράνομης, απειλητικής,
                  καταχρηστικής, δυσφημιστικής, άσεμνης ή ανάρμοστης πληροφορίας
                  ή υλικού οποιουδήποτε είδους, το οποίο παραβιάζει τα
                  δικαιώματα οποιουδήποτε άλλου προσώπου, συμπεριλαμβανομένων
                  χωρίς περιορισμό οποιεσδήποτε διαβιβάσεις συνιστούν ή
                  ενθαρρύνουν συμπεριφορά που θα αποτελούσε ποινικό αδίκημα, θα
                  οδηγούσε σε αστική ευθύνη ή θα παραβίαζε οποιονδήποτε άλλο
                  εφαρμοστέο νόμο.
                </p>

                <p>
                  Οι αναγραφόμενες τιμές δίνονται από τους διαφημιζόμενους και
                  ουδεμία ευθύνη φέρει το MyGreekApp αν αυτές δεν
                  ανταποκρίνονται στην πραγματικότητα.
                </p>

                <p>
                  Το MyGreekApp δεν μπορεί και δεν εγγυάται ότι οποιοδήποτε
                  περιεχόμενο ή αρχεία που είναι διαθέσιμα για λήψη μέσω του
                  MyGreekApp ή παραδίδονται μέσω ηλεκτρονικού ταχυδρομείου μέσω
                  του MyGreekApp θα είναι απαλλαγμένα από ιούς, worms, Trojan
                  horses, ή άλλους κώδικες που εκδηλώνουν μολυσματικές ή
                  καταστροφικές ιδιότητες.
                </p>

                <p>
                  Παρακαλώ δείτε επίσης τη ρήτρα <a href="#7">07: Ευθύνη.</a>
                </p>

                <h2>
                  <a name="21">2.1 Κοινοί κανόνες</a>
                </h2>

                <ul>
                  <li>
                    Μόνο ένας λογαριασμός είναι διαθέσιμος για κάθε χρήστη.
                  </li>

                  <li>
                    Τα στοιχεία επικοινωνίας θα πρέπει να προστίθενται στα
                    αντίστοιχα πεδία και δεν επιτρέπεται να εμφανίζονται σε
                    τίτλους οι περιγραφές
                  </li>
                  <li>
                    Οι φωτογραφίες που χρησιμοποιούνται παρακαλούμε, να ειναι
                    καθαρές, σαφείς και ακριβείς. Επιπλεόν οι φωτογραφίες δεν
                    μπορούν να περιέχουν στοιχεία επικοινωνίας (αριθμός
                    τηλεφώνου, διεύθυνση, ηλεκτρονικό ταχυδρομείο, σύνδεσμο στο
                    facebook).
                  </li>
                  <li>
                    Απαγορεύεται η δημοσίευση με φωτογραφίες που δεν σας ανήκουν
                  </li>
                  <li>
                    Είναι αποδεκτό μόνο από νομικά πρόσωπα, μια φωτογραφία να
                    περιέχει ένα λογότυπο γραμμένο μόνο στο κάτω μέρος ή στη
                    μέση της φωτογραφίας με μικρά και διαφανή γράμματα
                  </li>
                  <li>
                    Δημοσιεύστε την αγγελία σας στην κατάλληλη κατηγορία για να
                    αποκτήσετε περισσότερη έκθεση και οι χρήστες που το
                    αναζητούν να το εντοπίζουν πιο εύκολα
                  </li>
                  <li>
                    Δεν μπορείτε να χρησιμοποιήσετε το όνομα MyGreekApp στην
                    περιγραφή της ανάρτησης σας
                  </li>
                  <li>
                    Παρακαλώ μην στέλνετε ανεπιθύμητα μηνύματα. Αυτό
                    περιλαμβάνει ανεπιθύμητες αγγελίες και ανεπιθύμητες λέξεις
                    στις αγγελίες
                  </li>
                </ul>

                <h2>
                  <a id="23" name="23"></a>
                  <a name="23">2.2 Απαγορευμένες αναρτήσεις</a>
                </h2>

                <p>Απαγορεύεται η ανάρτηση αγγελιών με περιεχόμενο:</p>

                <ul>
                  <li>Προώθηση ναρκωτικών</li>
                  <li>Αλκοόλ</li>
                  <li>Διακοσμητικά μέρη ζώου</li>
                  <li>
                    Τσιγάρα, ηλεκτρονικά τσιγάρα, υγρά για κάπνισμα και συναφή
                    προϊόντα
                  </li>
                  <li>Ανταγωνιστικές ιστοσελίδες</li>
                  <li>Επικίνδυνα ή / και παράνομα αέρια και ουσίες</li>
                  <li>Φάρμακα και παράγωγα φαρμάκων</li>
                  <li>Φάρμακα διαθέσιμα μόνο με ιατρική συνταγή</li>
                  <li>Τρόφιμα, ποτά και συμπληρώματα</li>
                  <li>
                    Πυροβόλα όπλα και πυρομαχικά (ακόμη και αν είναι νόμιμο να
                    τα αγοράσουν)
                  </li>
                  <li>Ατσάλινα όπλα (μαχαίρι κ.λπ.)</li>
                  <li>Πυροτεχνήματα οποιουδήποτε είδους</li>
                  <li>Τυχερά παιχνίδια</li>
                  <li>
                    Ιστορικά αντικείμενα / προϊόντα προστατευόμενης κληρονομιάς
                  </li>
                  <li>Προσωπικές πληροφορίες ή λίστες αλληλογραφίας</li>
                  <li>Προπαγάνδα</li>
                  <li>Κλεμμένα αντικείμενα</li>
                  <li>Τρομοκρατική προπαγάνδα / αξεσουάρ</li>
                  <li>
                    Απειλούμενη / προστατευόμενη άγρια φύση, φυτά και έντομα
                  </li>
                  <li>
                    Διακρίσεις και απειλητική, βίαιη ή προσβλητική συμπεριφορά
                  </li>
                  <li>Ανθρώπινο εμπόριο</li>
                  <li>
                    Οποιοδήποτε είδος νομίσματος (εξαιρουμένων των συλλεκτικών)
                  </li>
                  <li>Δωροκάρτες, κουπόνια, πιστοποιητικά δώρων</li>
                  <li>Μαντεία, υπηρεσίες μαγείας, προμήθειες και αγαθά</li>
                  <li>Συσκευές προσωπικής υγιεινής</li>
                  <li>
                    Online και offline χρηματικά δάνεια, εταιρική ρευστοποίηση
                    και πιστωτικές υπηρεσίες
                  </li>
                  <li>
                    Πολυεπίπεδο μάρκετινγκ (MLM), πυραμίδα, δικτυακό μάρκετινγκ
                  </li>
                  <li>
                    Οποιαδήποτε άλλη δραστηριότητα ή αντικείμενο που να
                    αντιβαίνει στο εγχώριο και διεθνές δίκαιο.
                  </li>
                </ul>

                <h2>
                  <a id="3" name="3">
                    03. Κατάχρηση του MyGreekApp
                  </a>
                </h2>

                <p>
                  Το MyGreekApp και οι χρήστες του MyGreekApp εργάζονται μαζί
                  για να διατηρήσουν τη σωστή λειτουργία του MyGreekApp και την
                  ασφαλή κοινότητα. Παρακαλούμε, αναφέρετε προβλήματα,
                  προσβλητικό περιεχόμενο και παραβιάσεις της πολιτικής μας
                  χρησιμοποιώντας το σύστημα αναφοράς.
                </p>

                <p>
                  Χωρίς να περιορίζουμε άλλα διορθωτικά μέτρα, ενδέχεται να
                  εκδώσουμε προειδοποιήσεις, να περιορίσουμε ή να τερματίσουμε
                  την υπηρεσία μας, να καταργήσουμε το φιλοξενούμενο περιεχόμενο
                  και να λάβουμε τεχνικά και νομικά μέτρα για να αποτρέψουμε
                  χρήστες από το MyGreekApp αν πιστεύουμε ότι δημιουργούν
                  προβλήματα, παραβιάζοντας τα δικαιώματα τρίτων ή ενεργώντας
                  ασυμβίβαστα με τους παρόντες Όρους και Προϋποθέσεις
                  (συμπεριλαμβανομένων, χωρίς περιορισμό, παρακάμψεις προσωρινών
                  ή μόνιμων αναστολών ή παρενοχλήσεις προσωπικού του MyGreekApp
                  ή άλλων χρηστών). Ωστόσο, αν αποφασίσουμε να προβούμε σε
                  οποιοδήποτε από αυτά τα βήματα, να αφαιρέσουμε το
                  φιλοξενούμενο περιεχόμενο ή να διατηρήσουμε έναν χρήστη εκτός
                  του MyGreekApp ή όχι, δεν έχουμε καμία υποχρέωση να
                  παρακολουθούμε τις πληροφορίες που μεταδίδονται ή
                  αποθηκεύονται στο MyGreekApp και δεν αναλαμβάνουμε καμία
                  ευθύνη για μη εξουσιοδοτημένο ή παράνομο περιεχόμενο στο
                  MyGreekApp ή χρήση του MyGreekApp από τους χρήστες.
                </p>

                <h2>
                  <a id="4" name="4">
                    04. Χρεώσεις και υπηρεσίες
                  </a>
                </h2>
                <p>
                  Η χρήση του MyGreekApp προσφέρεται καταρχάς χωρίς χρέωση, με
                  εξαίρεση τη καταχώριση υπηερσιών και επιχειρήσεων στην
                  πλατφόρμα.
                  <br />
                  Για να καταχωριθούν οι αγγελίες, ο χρήστης μπορεί να πληρώσει
                  μέσω apple-in-app-pay η google-in-app-pay. Οι αγγελίες
                  εργασίας πληρώνονται μία μοναδική φορά. Οι καταχωρίσεις
                  επιχείρησης η υπηρεσίας, λειτουργούν με σύστημα subscription.
                  Η apple ή η google θα τραβάει αυτομάτως τα χρήματα 1 φορά τον
                  χρόνο. Τo subscription μπορεί να διακοππεί οποιά στιγμή
                  θελήσει ο χρήστης, και θα ισχύει για το χρονικό διάστημα για
                  το οποίο πλήρωσε.
                </p>
                <ul>
                  <li>
                    <a href="https://support.apple.com/guide/ipad/manage-purchases-subscriptions-settings-ipadee10c6e7/ipados">
                      Διακοπή subscription σε iOS (apple)
                    </a>
                  </li>
                  <li>
                    <a href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid#:~:text=On%20your%20Android%20device%2C%20go,Follow%20the%20instructions.">
                      Διακοπή subscription σε android (google)
                    </a>
                  </li>
                </ul>

                <p>
                  Συμφωνείτε ότι οι αγγελίες σας ενδέχεται να διαγραφούν από το
                  MyGreekApp σε περίπτωση που πιστεύουμε ότι οι παρόντες Όροι
                  και Προϋποθέσεις, συμπεριλαμβανομένων των Κανόνων Δημοσίευσης,
                  παραβιάζονται, είτε άμεσα είτε έμμεσα και διατηρούμε το
                  δικαίωμα να μην επιστρέψουμε στους χρήστες που τις ανάρτησαν
                  τα τέλη που τυχόν έχουν καταβληθεί.
                </p>

                <p>
                  Παρακαλώ δείτε επίσης τη ρήτρα
                  <a href="#14">
                    14: Πολιτική επιστροφής χρημάτων και ακύρωσης.
                  </a>
                </p>

                <h2>
                  <a id="5" name="5">
                    05. Περιεχόμενο
                  </a>
                </h2>

                <ol>
                  <li>
                    <p>
                      Το MyGreekApp περιέχει περιεχόμενο από εμάς, εσάς και
                      άλλους χρήστες. Το MyGreekApp προστατεύεται από νόμους
                      περί πνευματικής ιδιοκτησίας και διεθνείς συνθήκες. Το
                      περιεχόμενο που εμφανίζεται στο ή μεσω του MyGreekApp
                      προστατεύεται ως συλλογικό έργο ή / και συλλογή, σύμφωνα
                      με τους νόμους περί πνευματικών δικαιωμάτων και τις
                      διεθνείς συμβάσεις. Συμφωνείτε να μην αντιγράψετε,
                      διανείμετε ή τροποποιήσετε περιεχόμενο από το MyGreekApp
                      χωρίς τη ρητή έγγραφη συναίνεσή μας. Δεν μπορείτε να
                      αποσυναρμολογήσετε, να αναδιαμορφώσετε, να αναστρέψετε ή
                      να επιχειρήσετε να ανακαλύψετε οποιονδήποτε πηγαίο κώδικα
                      που περιέχεται στο MyGreekApp. Χωρίς να περιορίζουμε τα
                      παραπάνω, συμφωνείτε να μην αναπαράγετε, να αντιγράφετε,
                      να πουλάτε, να μεταπωλείτε ή να εκμεταλλεύεστε για
                      οποιονδήποτε σκοπό οποιαδήποτε πτυχή του MyGreekApp (εκτός
                      από το δικό σας περιεχόμενο). Όταν μας δίνετε περιεχόμενο,
                      μας παρέχετε και δηλώνετε ότι έχετε το δικαίωμα να μας
                      χορηγήσετε, ένα μη αποκλειστικό, παγκόσμιο, διαρκή,
                      αμετάκλητο, απαλλαγμένο από δικαιώματα πνευματικής
                      ιδιοκτησίας, (με πολλαπλά επίπεδα) το δικαίωμα άσκησης
                      οποιουδήποτε και όλων των δικαιωμάτων πνευματικής
                      ιδιοκτησίας, δημοσιότητας, εμπορικών σημάτων, σχεδιασμού,
                      βάσης δεδομένων και δικαιωμάτων πνευματικής ιδιοκτησίας σε
                      αυτό το περιεχόμενο, σε οποιοδήποτε μέσο είτε είναι γνωστό
                      είτε πρόκειται να ανακαλυφθεί στο μέλλον.
                    </p>
                  </li>
                  <li>
                    <p>
                      Ενδέχεται να μην έχετε την ευκαιρία να ελέγξετε όλα τα
                      συμπληρωματικά δεδομένα πριν δημοσιεύσετε μια αγγελία. Το
                      MyGreekApp δεν ευθύνεται για την ακρίβεια των
                      συμπληρωματικών δεδομένων που παρέχονται από τρίτους.
                      Συνεχίζετε να είστε υπεύθυνοι, διασφαλίζοντας ότι οι
                      αγγελίες σας είναι ακριβείς, δεν περιλαμβάνουν
                      παραπλανητικές πληροφορίες και συμμορφώνονται πλήρως με
                      τους παρόντες Όρους και Προϋποθέσεις. Αν παρατηρήσετε
                      ανακρίβειες στα δεδομένα αυτά μετά την εμφάνιση της
                      αγγελίας σας στην ιστοσελίδα, επικοινωνήστε μαζί μας.
                    </p>
                  </li>
                </ol>

                <h2>
                  <a id="6" name="6">
                    06. Παράβαση
                  </a>
                </h2>

                <p>
                  Απαγορεύεται η δημοσίευση περιεχομένου που παραβιάζει
                  δικαιώματα τρίτων. Η απαγόρευση αυτή περιλαμβάνει αλλά δεν
                  περιορίζεται σε περιεχόμενο που παραβιάζει τα δικαιώματα
                  πνευματικής ιδιοκτησίας όπως τα πνευματικά δικαιώματα, το
                  σχέδιο και το εμπορικό σήμα (π.χ. προσφορά πλαστών
                  αντικειμένων προς πώληση). Ένας μεγάλος αριθμός προϊόντων όλων
                  των ειδών προσφέρεται στο MyGreekApp από ιδιώτες Οι
                  δικαιούχοι, ιδίως οι κάτοχοι δικαιωμάτων πνευματικής
                  ιδιοκτησίας, δικαιωμάτων εμπορικών σημάτων ή άλλων
                  δικαιωμάτων, μπορούν να αναφέρουν τυχόν αγγελία που ενδέχεται
                  να παραβιάζει τα δικαιώματά τους και να υποβάλουν αίτηση για
                  κατάργηση αυτής της αγγελίας. Εάν ένας νόμιμος εκπρόσωπος του
                  δικαιούχου μας, μας το αναφέρει με τον σωστό τρόπο, τα
                  προϊόντα που παραβιάζουν τα πνευματικά δικαιώματα θα
                  αφαιρεθούν από το MyGreekApp.
                </p>

                <h2>
                  <a id="7" name="7">
                    07.Ευθύνη
                  </a>
                </h2>

                <p>
                  Οι παρόντες όροι και προϋποθέσεις δεν περιορίζουν τη
                  στοιχειοθέτηση ευθύνης του/ την ευθύνη που φέρει το MyGreekApp
                  στην περίπτωση των ψευδών δηλώσεων ή θάνατο ή τραυματισμό που
                  οφείλεται σε αμέλεια του ή των αντιπροσώπων ή των υπαλλήλων
                  του. Συμφωνείτε και δεσμεύεστε ότι δεν θα μας καταστήσετε
                  υπεύθυνους για ενέργειες στις οποίες προβαίνουν άλλοι χρήστες
                  ή για δημοσιεύσεις άλλων χρηστών.
                </p>

                <p>
                  Δεν αποτελεί αρμοδιότητα μας να εξετάζουμε τις δημοσιεύσεις
                  χρηστών, δεν συμμετέχουμε στις πραγματικές συναλλαγές μεταξύ
                  χρηστών. Το μεγαλύτερο μέρος του περιεχομένου του MyGreekApp
                  προέρχεται από άλλους χρήστες. Το MyGreekApp δεν εγγυάται την
                  ακρίβεια των καταχωρήσεων ή τις επικοινωνίες χρηστών ή την
                  ποιότητα, την ασφάλεια ή τη νομιμότητα του τι προσφέρεται.
                </p>

                <p>
                  Σε καμία περίπτωση δεν αναλαμβάνουμε ευθύνη οποιασδήποτε φύσης
                  για την απόσπαση οποιασδήποτε παράνομης, απειλητικής,
                  καταχρηστικής, δυσφημιστικής, άσεμνης ή ανάρμοστης πληροφορίας
                  ή υλικού οποιουδήποτε είδους το οποίο παραβιάζει ή καταπατά τα
                  δικαιώματα οποιουδήποτε άλλου προσώπου, συμπεριλαμβανομένων,
                  χωρίς περιορισμό οποιεσδήποτε μεταδόσεις που συνιστούν ή
                  ενθαρρύνουν συμπεριφορά που θα αποτελούσε ποινικό αδίκημα, θα
                  οδηγούσε σε αστική ευθύνη ή θα παραβίαζε οποιονδήποτε άλλο
                  εφαρμοστέο νόμο.
                </p>

                <p>
                  Συμφωνείτε και αποδέχεστε ότι δεν εγγυόμαστε συνεχή, χωρίς
                  λάθη ή ασφαλή πρόσβαση στις υπηρεσίες μας ή ότι τα ελαττώματα
                  στην υπηρεσία θα διορθωθούν. Παρόλο που θα καταβάλλουμε
                  εύλογες προσπάθειες για τη διατήρηση μιας αδιάκοπης υπηρεσίας,
                  δεν δίνουμε υποσχέσεις ή εγγυήσεις (είτε ρητές είτε σιωπηρές)
                  σχετικά με τη λειτουργία και τη διαθεσιμότητα των ιστότοπων,
                  υπηρεσιών, εφαρμογών ή εργαλείων μας.
                </p>

                <p>
                  Ως εκ τούτου, στο βαθμό που επιτρέπεται από νομική άποψη,
                  αποποιούμαστε ρητά όλες τις εγγυήσεις, παραστάσεις και όρους,
                  ρητές ή σιωπηρές, συμπεριλαμβανομένων εκείνων που αφορούν την
                  ποιότητα, την εμπορευσιμότητα, την εμπορεύσιμη ποιότητα, την
                  ανθεκτικότητα, την καταλληλότητα για συγκεκριμένο σκοπό και
                  εκείνων που προκύπτουν από το νόμο. Δεν είμαστε υπεύθυνοι για
                  τυχόν απώλεια, είτε χρημάτων (συμπεριλαμβανομένου του
                  κέρδους), υπεραξίας ή φήμης, ή οποιωνδήποτε ειδικών, έμμεσων ή
                  επακόλουθων ζημιών που προκύπτουν από τη χρήση ή την αδυναμία
                  χρήσης του MyGreekApp, ακόμα και αν μας συμβουλεύετε ή αν
                  μπορούσαμε λογικά να προβλέψουμε την πιθανότητα τέτοιου είδους
                  ζημιών. Ορισμένες δικαιοδοσίες δεν επιτρέπουν την αποποίηση
                  εγγυήσεων ή τον αποκλεισμό των ζημιών, συνεπώς αυτές οι
                  αποποιήσεις ευθυνών και εξαιρέσεων ενδέχεται να μην ισχύουν
                  για εσάς.
                </p>

                <p>
                  Οι αγοραπωλησίες και οι συναλλαγές πραγματοποιούνται με ευθύνη
                  αποκλειστικά και μόνο των χρηστών και δεσμεύουν αποκλειστικά
                  και μόνο τους χρήστες αυτούς. Το MyGreekApp δεν καθίσταται
                  συμβαλλόμενο μέρος και δεν συμμετέχει καθ οποιονδήποτε τρόπο
                  σε οποιαδήποτε σύμβαση αγοραπωλησίας ή άλλη συμφωνία συναφθεί
                  μεταξύ των χρηστών άμεσα ή έμμεσα μέσω της χρήσης της
                  ιστοσελίδας ή/και των παρεχόμενων μέσω αυτής υπηρεσιών. Το
                  MyGreekApp σε καμία περίπτωση δεν δεσμεύεται από τέτοιου
                  είδους συμβάσεις ή συμφωνίες που συνάπτονται μεταξύ των
                  χρηστών και δεν αποκτά δικαιώματα η υποχρεώσεις να
                  εκπορεύονται από αυτές.
                </p>

                <p>
                  Συνεπεία των ανωτέρω, το MyGreekApp δεν αναλαμβάνει καμία
                  ευθύνη και αποποιείται οποιονδήποτε τυχόν αξιώσεων (ανεξάρτητα
                  από το εάν αυτές είναι αστικές, ποινικές, έμμεσες ή
                  παρεπόμενες) εξαιτίας οποιασδήποτε διαφοράς, πράξης ή
                  παράλειψης σε σχέση με την διαπραγμάτευση, σύναψη, εγκυρότητα
                  ή την εκτέλεση μιας τέτοιας συμφωνίας ή συμβάσεως και εν γένει
                  εξαιτίας οποιασδήποτε διαφοράς που προκύπτει μεταξύ Πωλητή και
                  Αγοραστή.
                </p>

                <p>
                  Ενδεικτικά και όχι περιοριστικά, το MyGreekApp δεν φέρει καμία
                  απολύτως ευθύνη και αποποιείται οποιασδήποτε αξιώσεως ως προς
                  τα εξής:
                </p>

                <ol>
                  <li>
                    <p>Διαπραγμάτευση και Εκτέλεση των Όρων της Συμβάσεως</p>

                    <p>
                      Καθίσταται σαφές ότι ο Πωλητής και ο Αγοραστής είναι
                      ελεύθεροι να διαπραγματευτούν και να συμφωνήσουν τους
                      ειδικότερους όρους της μεταξύ τους συναλλαγής. Οι Χρήστες
                      μπορούν να συμβληθούν με τον τρόπο και τον τύπο που
                      εκείνοι και μόνον θα επιλέξουν ιδία ευθύνη. Το MyGreekApp
                      δεν λαμβάνει μέρος και δεν φέρει καμία ευθύνη ως προς την
                      διαπραγμάτευση, αλλά ούτε ως προς την εκτέλεση των
                      παραπάνω όρων.
                    </p>

                    <p>
                      Περαιτέρω, αναγνωρίζετε ότι το MyGreekApp δεν φέρει καμία
                      ευθύνη για οποιαδήποτε τυχόν ζημία που προκαλείται σε
                      άτομα, αντικείμενα, περιουσιακά και άλλα στοιχεία, ή για
                      άλλες άμεσες, έμμεσες ή παρεπόμενες ζημίες που ο Χρήστης ή
                      τρίτος υφίσταται εξαιτίας ή με αφορμή την σύναψη ή
                      εκτέλεση συμβάσεως αγοραπωλησίας.
                    </p>
                  </li>
                  <li>
                    <p>Οικονομικοί Όροι – Συναλλαγή</p>

                    <p>
                      Καθώς το MyGreekApp δεν συμμετέχει και δεν αποτελεί μέρος
                      της συμφωνίας ή της σύμβασης την οποία συνάπτει ο Πωλητής
                      με το Αγοραστή, δεν εμπλέκεται ούτε με τη διαμόρφωση των
                      οικονομικών όρων που θα διέπουν αυτή την συμφωνία ή
                      σύμβαση, όπως ενδεικτικά και όχι περιοριστικά, με το ύψος
                      του τιμήματος της αγοραπωλησίας, τον χρόνο, τρόπο και τόπο
                      εξόφλησής του, αλλά ούτε με την τήρηση και εκτέλεση των
                      όρων αυτών.
                    </p>

                    <p>
                      Το τίμημα το οποίο μπορεί να αναφέρεται σε μία
                      δημοσιευμένη Αγγελία ενδέχεται να είναι ενδεικτικό και τα
                      μέρη να συμφωνήσουν μεγαλύτερο ή μικρότερο τίμημα.
                      Συνιστά, επομένως, αποκλειστική ευθύνη των συμβαλλομένων
                      Χρηστών, να συμφωνήσουν τους ανωτέρω όρους ως και να
                      εκπληρώσουν αυτούς ενώ, καθίσταται σαφές ότι ο Πωλητής
                      δύναται να αξιώσει αποκλειστικά και μόνο από τον Αγοραστή
                      (και σε καμία περίπτωση από το MyGreekApp) το συμφωνηθέν
                      τίμημα.
                    </p>
                  </li>
                  <li>
                    <p>
                      Διαφορές ως προς την ποιότητα και τα χαρακτηριστικά των
                      Αντικειμένων
                    </p>

                    <p>
                      Το MyGreekApp δε φέρει καμία ευθύνη για την ασφάλεια ή
                      γνησιότητα των υπηρεσιών, για τυχόν ενυπάρχοντα πραγματικά
                      ή νομικά ελαττώματα. Σε περίπτωση που προκύψει οποιαδήποτε
                      διαφορά μεταξύ των Χρηστών σχετικά με τα παραπάνω,
                      αποδέχεσθε ρητά ότι το MyGreekApp δεν φέρει καμία ευθύνη,
                      ως εκ τούτου δεν υφίσταται καμία υποχρέωση εκ μέρος του
                      MyGreekApp για παροχή αστικής αποκατάστασης, αποζημίωσης ή
                      καταβολή εξόδων στους Χρήστες του.
                    </p>
                  </li>
                  <li>
                    <p>Τήρηση της Νομοθεσίας</p>

                    <p>
                      Συνιστά αποκλειστική ευθύνη των Χρηστών να τηρούν την
                      εκάστοτε ισχύουσα νομοθεσία και τις αρχές που επιβάλλουν η
                      καλή πίστη και τα συναλλακτικά ήθη και να μεριμνούν ότι το
                      περιεχόμενο της μεταξύ τους συμφωνίας ή σύμβασης αλλά και
                      κάθε πράξη ή ενέργειά τους δεν αντίκειται στα ανωτέρω.
                    </p>

                    <p>
                      Το MyGreekApp εφιστά ενδεικτικά την προσοχή των Χρηστών
                      στο ότι πρέπει να μεριμνούν για την τυχόν υποχρέωση
                      επιβολής και απόδοσης Φ.Π.Α. και για την τυχόν υποχρέωση
                      έκδοση αποδείξεως σε κάθε μεταξύ τους οικονομική
                      συναλλαγή, σύμφωνα με τα οριζόμενα στο νόμο. Η τήρηση της
                      φορολογικής νομοθεσίας αποτελεί αποκλειστική ευθύνη των
                      Χρηστών και το MyGreekApp δεν φέρει καμία ευθύνη και
                      αποποιείται οποιασδήποτε αξιώσεως αναφορικά με τα
                      παραπάνω.
                    </p>
                  </li>
                  <li>
                    <p>Αποποίηση Ευθύνης</p>

                    <p>
                      Η χρήση της εφαρμογής συνεπάγεται δέσμευση με τους όρους
                      και προϋποθέσεις. Οι πληροφορίες που εμφανίζονται στην
                      εφαρμογή προορίζονται να παρέχουν στους χρήστες
                      πληροφορίες που ενδεχομένως να τους ενδιαφέρουν.
                      Καταβάλλεται κάθε δυνατή προσπάθεια προκειμένου να είναι
                      επίκαιρες και ακριβείς πληροφορίες, ωστόσο δεν είναι
                      απίθανο να προκύψουν και σφάλματα.
                    </p>

                    <p>
                      Η ατομική Εταιρεία Michail Mangasarov αποποιείται
                      οποιαδήποτε ευθύνη για οποιαδήποτε σφάλματα ή ελλείψεις
                      στο περιεχόμενο αυτής της εφαρμογής.
                    </p>

                    <p>
                      Το σύνολο του περιεχομένου της εφαρμογής και όλων των
                      περιεχομένων μέσω αυτού υπηρεσιών παρέχεται «ως έχει»
                      χωρίς καμία εγγύηση πληρότητας, ακρίβειας ή
                      διαχρονικότητας και χωρίς πιστοποιήσεις, εγγυήσεις ή
                      οποιαδήποτε συμβατική δέσμευση, ρητή ή σιωπηρή. Η ατομική
                      Εταιρεία Michail Mangasarov δεν εγγυάται ότι η παρούσα
                      εφαρμογή, οι διάφορες υπηρεσίες που παρέχονται μέσω αυτής
                      ή/και οποιεσδήποτε πληροφορίες ή άλλο υλικό που
                      μεταφορτώνεται από αυτή την εφαρμογή, θα είναι ακριβείς,
                      επίκαιρες, αδιάκοπες, χωρίς σφάλματα, χωρίς ιούς ή άλλα
                      βλαβερά στοιχεία.
                    </p>

                    <p>
                      Στο μέγιστο βαθμό που επιτρέπεται από το εφαρμοστέο
                      δίκαιο, η ατομική Εταιρεία Michail Mangasarov αποποιείται
                      κάθε ευθύνη απέναντι σε εσάς και κάθε άλλο πρόσωπο σχετικά
                      με το περιεχόμενο αυτής της εφαρμογής και με όλες τις
                      υπηρεσίες που παρέχονται σε αυτήν, εξ απόψεως
                      αδικοπραξίας, σύμβασης, εγγύησης, ευθύνης ή αμέλειας ή σε
                      οτιδήποτε άλλο προκύψει και σχετικά με έμμεση, άμεση,
                      επακόλουθη, ειδική, ποινική ή παρεπόμενη ζημιά, ακόμη και
                      στην περίπτωση που η ατομική Εταιρεία Michail Mangasarov
                      έχει ενημερωθεί, γνωρίζει ή θα έπρεπε να γνωρίζει την
                      πιθανότητα τέτοιας ζημιάς.
                    </p>
                  </li>
                  <li>
                    <p>Παραπομπές και σύνδεσμοι σε ιστοσελίδες τρίτων</p>

                    <p>
                      Η εφαρμογή δύναται να περιέχει παραπομπές ή/και συνδέσμους
                      από και προς άλλες Ιστοσελίδες. Εάν ανατρέξετε σε κάποια
                      από αυτές ή ακολουθήσετε κάποιο σύνδεσμο σε οποιαδήποτε
                      από τις άλλες Ιστοσελίδες, αυτό σημαίνει ότι έχετε βγει
                      από την Ιστοσελίδα και θα πρέπει να γνωρίζετε ότι η
                      Εταιρεία δεν έχει οποιοδήποτε έλεγχο πάνω στις εν λόγω
                      άλλες Ιστοσελίδες και ούτε τις κατέχει ή/και διατηρεί. H
                      Εταιρεία δεν έχει οποιαδήποτε υποχρέωση ή/και ευθύνη και
                      σε καμία περίπτωση δεν αποδέχεται και αποποιείται
                      οποιαδήποτε και όλες τις υποχρεώσεις ή ευθύνες για το
                      περιεχόμενο και το υλικό που παρέχεται στις άλλες
                      Ιστοσελίδες και για την προστασία και εμπιστευτικότητα
                      οποιασδήποτε πληροφορίας που μπορεί να παράσχεται κατά την
                      επίσκεψη σας σε άλλες Ιστοσελίδες. Τα μέρη που κατέχουν
                      και διατηρούν ή/και συνδέονται με τις άλλες Ιστοσελίδες
                      έχουν την πλήρη ευθύνη, είτε αστική είτε ποινική για την
                      ασφάλεια, νομιμότητα ή αξιοπιστία των περιεχομένων της
                      Ιστοσελίδας τους και των υπηρεσιών τους χωρίς η ατομική
                      Εταιρεία Michail Mangasarov να έχει οποιαδήποτε και καμία
                      ευθύνη.
                    </p>
                  </li>
                </ol>

                <p>
                  Παρακαλώ δείτε επίσης τη ρήτρα
                  <a href="#2">
                    02: Κανόνες για την ανάρτηση των αγγελιών ('Κανόνες
                    δημοσίευσης')
                  </a>
                </p>

                <h2>
                  <a id="8" name="8">
                    08. Απαλλαγή
                  </a>
                </h2>

                <p>
                  Εάν έχετε διαφωνία με έναν ή περισσότερους χρήστες του
                  MyGreekApp, μας απελευθερώνετε (και τα στελέχη, τους
                  διευθυντές, τους αντιπροσώπους μας, τις θυγατρικές εταιρείες
                  μας, τις κοινοπραξίες και τους υπαλλήλους μας) από
                  οποιεσδήποτε αξιώσεις, απαιτήσεις και ζημίες (πραγματικές και
                  επακόλουθες) κάθε είδους και φύσης, γνωστές ή άγνωστες, οι
                  οποίες προκύπτουν από ή συνδέονται κατά κάποιο τρόπο με
                  τέτοιες διαφωνίες
                </p>

                <h2>
                  <a id="9" name="9">
                    09. Δικαιώματα τρίτων
                  </a>
                </h2>

                <p>
                  Ένα πρόσωπο που δεν είναι συμβαλλόμενο μέρος της παρούσας
                  συμφωνίας δεν έχει κανένα δικαίωμα να επιβάλει οποιονδήποτε
                  όρο της παρούσας συμφωνίας, αλλά αυτό δεν επηρεάζει κανένα
                  δικαίωμα ή αποκατάσταση τρίτου που ορίζεται στην παρούσα
                  Συμφωνία.
                </p>

                <h2>
                  <a id="10" name="10">
                    10. Προσωπικά Δεδομένα
                  </a>
                </h2>

                <p>
                  Με τη χρήση του MyGreekApp, συμφωνείτε με τη συλλογή,
                  μεταφορά, αποθήκευση και χρήση των προσωπικών σας πληροφοριών
                  από το MyGreekApp, σε διακομιστές του MyGreekApp που
                  βρίσκονται σε διάφορες χώρες. Συμφωνείτε επίσης να λαμβάνετε
                  επικοινωνίες μάρκετινγκ από εμάς.
                </p>

                <p>
                  Δεσμευόμαστε ότι θα χρησιμοποιήσουμε τυχόν πληροφορίες οι
                  οποίες θα περιέλθουν εις γνώση μας για την παροχή των
                  υπηρεσιών μας με τη δέουσα εμπιστευτικότητα, εκτός σε
                  περίπτωση που η αποκάλυψη οποιασδήποτε τέτοιας πληροφορίας
                  καταστεί νομικά αναγκαία. Σε τέτοια περίπτωση το MyGreekApp θα
                  λάβει όλα τα δέοντα μέτρα ασφαλείας ως αυτό κριθεί απαραίτητο.
                </p>

                <p>
                  Δια του παρόντος αναγνωρίζετε και συμφωνείτε ότι οι
                  πληροφορίες καθώς και άλλα προσωπικά δεδομένα ως αυτά
                  απαιτηθούν θα χρησιμοποιηθούν στα πλαίσια και για τους σκοπούς
                  παροχής των υπηρεσιών από το MyGreekApp. Τα προσωπικά δεδομένα
                  δυνατό να χρησιμοποιηθούν, αποθηκευτούν, τύχουν επεξεργασίας
                  και διαβιβαστούν μόνο για τους σκοπούς παροχής των υπηρεσιών
                  που προσφέρει το MyGreekApp. Δια του παρόντος βεβαιώνετε ότι
                  συγκατατίθεστε στην παροχή των προσωπικών σας δεδομένων για
                  τους σκοπούς που αναφέρονται στην παρούσα.
                </p>

                <p>
                  Παρακαλώ δείτε επίσης τη ρήτρα
                  <a href="#15">15: Πολιτική Απορρήτου.</a>
                </p>

                <h2>
                  <a id="11" name="11">
                    11. Επίλυση διαφορών
                  </a>
                </h2>

                <p>
                  Αν προκύψει διαφωνία μεταξύ εσάς και του MyGreekApp, σας
                  συνιστούμε να επικοινωνήσετε πρώτα μαζί μας απευθείας για να
                  αναζητήσετε μια λύση, μεταβαίνοντας στη σελίδα Βοήθειας. Θα
                  εξετάσουμε εύλογα αιτήματα για την επίλυση της διαφοράς μέσω
                  εναλλακτικών διαδικασιών επίλυσης διαφορών, όπως η
                  διαμεσολάβηση ή η διαιτησία, ως εναλλακτικές λύσεις.
                </p>

                <p>
                  Η παρούσα συμφωνία διέπεται και ερμηνεύεται από κάθε άποψη από
                  τους νόμους της χώρα που βρίσκεται ο χρήστης. Επίσης,
                  συμφωνείτε ότι οποιαδήποτε απαίτηση ή διαφορά που ενδέχεται να
                  έχετε κατά της Εταιρείας ή / και του MyGreekApp και / ή του
                  προσωπικού της, πρέπει να επιλυθεί από τα δικαστήρια της
                  Κυπριακής Δημοκρατίας.
                </p>

                <h2>
                  <a id="12" name="12">
                    12. Γενικά
                  </a>
                </h2>

                <p>
                  Αν οποιαδήποτε διάταξη των παρόντων Όρων και Προϋποθέσεων
                  κηρυχθεί άκυρη ή για οποιονδήποτε άλλο λόγο ανεφάρμοστη, η
                  διάταξη αυτή θα διαγραφεί και δεν θα επηρεάσει την εγκυρότητα
                  και την εκτελεστότητα των υπόλοιπων διατάξεων.
                </p>

                <p>
                  Διατηρούμε το δικαίωμα, κατά τη διακριτική μας ευχέρεια, να
                  τροποποιήσουμε, να μετατρέψουμε ή να ενημερώσουμε με
                  οποιονδήποτε άλλο τρόπο τους Όρους και τις Προϋποθέσεις. Οι
                  τροποποιήσεις αυτές τίθενται σε ισχύ αμέσως μετά την ανάρτηση
                  τους. Εάν ο Χρήστης δεν συμφωνεί με την αλλαγή των Όρων
                  Χρήσης, θα πρέπει να σταματήσει να χρησιμοποιεί την εφαρμογή
                  του MyGreekApp και τις Υπηρεσίες του. Με τη χρήση του
                  MyGreekApp μετά τη δημοσίευση ειδοποίησης σχετικά με τέτοιες
                  τροποποιήσεις, μετατροπές ή ενημερώσεις, συμφωνείτε να
                  δεσμεύεστε από τους αναθεωρημένους Όρους και Προϋποθέσεις.
                </p>

                <p>
                  Το MyGreekApp διατηρεί το δικαίωμα να μετατρέψει ή να διακόψει
                  μερικώς ή ολικώς, προσωρινά ή οριστικά, την εφαρμογή και τις
                  Υπηρεσίες του με ή χωρίς πρότερη ενημέρωση του Χρήστη και ο
                  Χρήστης αποδέχεται πως ο ίδιος ή οποιοσδήποτε τρίτος δεν
                  διατηρεί προς το MyGreekApp οποιαδήποτε αξίωση για οποιαδήποτε
                  τυχόν μετατροπή ή διακοπή της εφαρμογής και των Υπηρεσιών.
                </p>

                <h2>
                  <a id="13" name="13">
                    13. Όροι χρήσης από κινητές συσκευές
                  </a>
                </h2>

                <p>
                  Εάν έχετε πρόσβαση στο MyGreekApp από μια κινητή συσκευή
                  χρησιμοποιώντας το MyGreekApp Mobile Application (η "Εφαρμογή
                  MyGreekApp"), ισχύουν για εσάς οι ακόλουθοι όροι χρήσης (οι
                  "Όροι Χρήσης") μαζί με την ισχύουσα Προστασία προσωπικών
                  δεδομένων και Νομική ειδοποίηση ή Άδεια Χρήσης τελικού χρήστη,
                  ανάλογα με την περίπτωση. Η χρήση της εφαρμογής, επιβεβαιώνει
                  τη συμφωνία σας με τους Όρους και Προϋποθέσεις και τους Όρους
                  Χρήσης.
                </p>

                <p>
                  Χρήση εφαρμογής. Το MyGreekApp σας δίνει το δικαίωμα να
                  χρησιμοποιήσετε την εφαρμογή MyGreekApp μόνο για προσωπική
                  χρήση. Πρέπει να συμμορφώνεστε με όλους τους ισχύοντες νόμους
                  και τους όρους συμφωνίας τρίτων μερών όταν χρησιμοποιείτε την
                  Εφαρμογή MyGreekApp (π.χ. συμφωνία ασύρματης παροχής
                  δεδομένων). Η εφαρμογή MyGreekApp ενδέχεται να μην περιέχει
                  την ίδια λειτουργικότητα που είναι διαθέσιμη στην εφαρμογή
                  MyGreekApp. Η λήψη και η χρήση της εφαρμογής MyGreekApp
                  γίνεται με δική σας διακριτική ευχέρεια και ρίσκο και είστε
                  αποκλειστικά υπεύθυνοι για οποιεσδήποτε ζημιές στη συσκευή σας
                  ή απώλεια δεδομένων που μπορεί να προκύψει από τη λήψη ή τη
                  χρήση της Εφαρμογής MyGreekApp.
                </p>

                <p>
                  Πνευματική Ιδιοκτησία - Εφαρμογές. Το MyGreekApp κατέχει, ή
                  είναι ο κάτοχος της άδειας σε όλα τα δικαιώματα, τον τίτλο και
                  συμφέροντα στην εφαρμογή MyGreekApp, συμπεριλαμβανομένων όλων
                  των δικαιωμάτων ευρεσιτεχνίας, των πνευματικών δικαιωμάτων, το
                  εμπορικό μυστικό, το εμπορικό σήμα και όλα τα άλλα δικαιώματα
                  πνευματικής ιδιοκτησίας, συμπεριλαμβανομένων όλων των
                  εφαρμογών, των ανανεώσεων, των επεκτάσεων και των
                  αποκαταστάσεων αυτών. Δεν θα τροποποιήσετε, προσαρμόσετε,
                  μεταφράσετε, προετοιμάσετε παράγωγα έργα από την αποσυμπίληση,
                  αντίστροφη μηχανική, αποσυναρμολόγηση ή άλλως να επιχειρήσετε
                  να αντλήσετε πηγαίο κώδικα από την εφαρμογή MyGreekApp και δεν
                  θα αφαιρέσετε, αποκρύψετε ή τροποποιήσετε την ειδοποίηση
                  πνευματικών δικαιωμάτων του MyGreekApp, εμπορικά σήματα ή άλλα
                  δικαιώματα πνευματικής ιδιοκτησίας, ειδοποιήσεις που έχουν
                  τοποθετηθεί, περιέχονται ή έχουν πρόσβαση σε συνδυασμό με ή με
                  οποιαδήποτε εφαρμογή του MyGreekApp.
                </p>

                <p>
                  Πρόσθετοι όροι. Πρόσθετοι όροι και προϋποθέσεις που ισχύουν
                  για εσάς με βάση την κινητή συσκευή, η εφαρμογή MyGreekApp που
                  είναι εγκατεστημένη σε:
                </p>

                <h2>
                  <a name="131">13.1 iOS - Apple</a>
                </h2>

                <p>
                  Οι παρόντες Όροι και Προϋποθέσεις και Όροι Χρήσης είναι μια
                  συμφωνία μεταξύ εσας και του MyGreekApp, και όχι με την Apple.
                  Η Apple δεν είναι υπεύθυνη για την εφαρμογή MyGreekApp και το
                  περιεχόμενό της.
                </p>

                <ol>
                  <li>
                    Το MyGreekApp σας παρέχει το δικαίωμα χρήσης της εφαρμογής
                    MyGreekApp μόνο σε προϊόν iOS που έχετε στην ιδιοκτησία ή
                    στον έλεγχο και όπως επιτρέπεται από τους Κανόνες Χρήσης που
                    ορίζονται στους Όρους Παροχής Υπηρεσιών του App Store.
                  </li>
                  <li>
                    Η Apple δεν έχει καμία υποχρέωση να παρέχει υπηρεσίες
                    συντήρησης και υποστήριξης σε σχέση με την εφαρμογή
                    MyGreekApp.
                  </li>
                  <li>
                    Η Apple δεν είναι υπεύθυνη για τη διερεύνηση, υπεράσπιση,
                    διευθέτηση και απαλλαγή από οποιαδήποτε απαίτηση πνευματικής
                    ιδιοκτησίας τρίτου μέρους.
                  </li>
                  <li>
                    Η Apple δεν φέρει καμία ευθύνη για την αντιμετώπιση
                    οποιωνδήποτε απαιτήσεων από εσάς ή τρίτων σχετικά με την
                    εφαρμογή MyGreekApp ή την κατοχή ή/και χρήση της εφαρμογής
                    MyGreekApp, συμπεριλαμβανομένων, ενδεικτικά, των εξής: α)
                    απαιτήσεις για ευθύνη προϊόντων (β) κάθε ισχυρισμός ότι η
                    εφαρμογή MyGreekApp δεν συμμορφώνεται με οποιαδήποτε
                    ισχύουσα νομική ή κανονιστική απαίτηση και γ) απαιτήσεις που
                    απορρέουν από την προστασία των καταναλωτών ή από ανάλογη
                    νομοθεσία.
                  </li>
                  <li>
                    Η Αpple και οι θυγατρικές εταιρίες της Apple είναι τρίτοι
                    δικαιούχοι αυτών των Όρων και Προϋποθέσεων και των Όρων
                    Χρήσης και, με την αποδοχή σας, η Apple ως τρίτος δικαιούχος
                    της θα έχει το δικαίωμα (και θα θεωρείται ότι έχει αποδεχθεί
                    το δικαίωμα) να επιβάλλει τους παρόντες Όρους και
                    Προϋποθέσεις και τους παρόντες Όρους Χρήσης εναντίον σας.
                  </li>
                </ol>

                <h2>
                  <a id="14" name="14">
                    14. Πολιτική επιστροφής χρημάτων και ακύρωσης υπηρεσιών
                  </a>
                </h2>

                <p>
                  Εάν η υπηρεσία που χρησιμοποιείτε απαιτεί πληρωμή, θα έχετε τη
                  δυνατότητα να ελέγξετε και να αποδεχτείτε τους όρους που θα
                  γνωστοποιούνται με σαφήνεια κατά τη στιγμή που θα δημοσιεύσετε
                  την αγγελία σας. Οι χρεώσεις μας αναφέρονται στο νομίσμα της
                  χώρας του χρήστη και ενδέχεται να διαφοροποιηθούν ανά πάσα
                  στιγμή. Μπορεί να επιλέξουμε να αλλάξουμε προσωρινά τις
                  χρεώσεις μας για προωθητικές εκδηλώσεις ή νέες υπηρεσίες.
                </p>

                <p>
                  Είστε υπεύθυνοι για την πληρωμή των οφειλόμενων χρεώσεων. Εάν
                  δεν το κάνετε, με την επιφύλαξη οποιουδήποτε άλλου δικαιώματος
                  ή ένδικου μέσου μπορεί να έχουμε το δικαίωμα σύμφωνα με τους
                  παρόντες Όρους και Προϋποθέσεις ή από το νόμο, ενδέχεται να
                  περιορίσουμε την ικανότητά σας να χρησιμοποιείτε το
                  MyGreekApp.
                </p>

                <p>
                  Αναγνωρίζετε ότι οι αγγελίες σας ενδέχεται να διαγραφούν από
                  το MyGreekApp σε περίπτωση που πιστεύουμε ότι οι παρόντες Όροι
                  και Προϋποθέσεις, συμπεριλαμβανομένων των Κανόνων Δημοσίευσης,
                  παραβιάζονται, είτε άμεσα είτε έμμεσα. Διατηρούμε το δικαίωμα
                  να μην επιστρέψουμε στους χρήστες του MyGreekApp τα τέλη που
                  καταβάλλονται μέσω της χρήσης του MyGreekApp.
                </p>

                <p>
                  Επιπλέον, οι χρήστες του MyGreekApp δεν μπορούν να ακυρώσουν
                  τις υπηρεσίες που έχουν ήδη καταβληθεί στο MyGreekApp.
                </p>

                <h2>
                  <a id="15" name="15">
                    15. Πολιτική Απορρήτου
                  </a>
                </h2>

                <p>Τελευταία ενημέρωση 12/01/2023</p>
                <p>
                  Η παρούσα Πολιτική Απορρήτου περιγράφει πως συλλέγουμε,
                  χρησιμοποιούμε, επεξεργαζόμαστε και κοινοποιούμε τις
                  πληροφορίες, συμπεριλαμβανομένων των προσωπικών πληροφοριών
                  για εσάς (στο εξής, «Χρήστες» ή «εσείς»), σε σχέση με την
                  πρόσβαση και την χρήση του της κινητής εφαρμογής MyGreekApp.
                </p>

                <p>
                  Πρέπει να διαβάσετε προσεκτικά την παρούσα Πολιτική Απορρήτου,
                  η οποία έχει γραφτεί, για να σας διευκολύνει στην κατανόηση
                  και για να αποφασίσετε ελεύθερα και με τη θέλησή σας αν
                  επιθυμείτε να παρέχετε τα προσωπικά σας δεδομένα ή αυτά τρίτων
                  μερών στο MyGreekApp.
                </p>

                <h2>
                  <a id="151" name="151">
                    15.1 Ποιος είναι ο Υπεύθυνος Επεξεργασίας;
                  </a>
                </h2>

                <p>
                  Υπεύθυνος Επεξεργασίας των προσωπικών δεδομένων των Χρηστών
                  της εφαρμογής MyGreekApp υπό την έννοια του Γενικού Κανονισμού
                  για την Προστασία των Προσωπικών Δεδομένων (ΕΕ) 2016/679
                  (ΓΚΠΔ) είναι o Michail Mangasarov, ο οποίος εδρεύει νομιμως
                  στην Γερμανία, επι της οδού Cimbernstraße 13a, 81377 München
                  όπως νομίμως εκπροσωπείται (e-mail:
                  info"παπακι"mygreekapp.com) (στο εξής η «Εταιρεία» ή «εμείς»).
                </p>

                <h2>
                  <a id="152" name="152">
                    15.2 Ποια προσωπικά δεδομένα συλλέγουμε;
                  </a>
                </h2>

                <p>
                  Συλλέγουμε και επεξεργαζόμαστε προσωπικές πληροφορίες που μας
                  παρέχετε σε σχέση με τα ακόλουθα:
                </p>

                <p>&nbsp;</p>

                <ul>
                  <li>
                    Πληροφορίες ταυτοποίησης, όπως το όνομά σας, αριθμό
                    τηλεφώνου και την διεύθυνση ηλεκτρονικού ταχυδρομείου, όταν
                    δημιουργείτε λογαριασμό στην εφαρμογή μας.
                  </li>
                </ul>

                <p>
                  Ακόμη, συλλέγουμε πληροφορίες αυτόματα όταν χρησιμοποιείτε τις
                  Υπηρεσίες μας, στις οποίες περιλαμβάνονται ενδεικτικά και όχι
                  περιοριστικά οι ακόλουθες:
                </p>

                <ul>
                  <li>
                    Αναγνωριστικό συσκευής ή αποκλειστικό αναγνωριστικό, τύπο
                    συσκευής, αναγνωριστικό για την αγγελία και μοναδικό
                    διακριτικό συσκευής
                  </li>
                  <li>
                    Πληροφορίες γεωγραφικής θέσης, συμπεριλαμβανομένων των
                    πληροφοριών τοποθεσίας από την κινητή συσκευή σας
                  </li>
                </ul>

                <p>
                  Μπορεί να σας επιτρέψουμε να μοιράζεστε πληροφορίες σε
                  ιστότοπους κοινωνικής δικτύωσης ή να χρησιμοποιείτε ιστότοπους
                  κοινωνικής δικτύωσης για να δημιουργήσετε το λογαριασμό σας ή
                  για να συνδέσετε το λογαριασμό σας με τον αντίστοιχο ιστότοπο
                  κοινωνικής δικτύωσης. Οι εν λόγω ιστότοποι κοινωνικής
                  δικτύωσης μπορεί να μας δώσουν αυτόματη πρόσβαση σε ορισμένες
                  προσωπικές πληροφορίες που διατηρούν σχετικά με εσάς. Μπορείτε
                  να ελέγξετε τις προσωπικές πληροφορίες που μας επιτρέπετε να
                  έχουμε πρόσβαση μέσω των ρυθμίσεων απορρήτου του εκάστοτε
                  ιστότοπου κοινωνικής δικτύωσης και τις άδειες που μας δίνετε
                  όταν μας παρέχετε πρόσβαση στα προσωπικά σας στοιχεία που
                  διατηρούνται από τον αντίστοιχο ιστότοπο κοινωνικής δικτύωσης.
                  Συνδέοντας έναν λογαριασμό που διαχειρίζεται ένας ιστότοπος
                  κοινωνικής δικτύωσης με το λογαριασμό σας και επιτρέποντάς μας
                  να έχουμε πρόσβαση σε αυτές τις πληροφορίες, συμφωνείτε ότι
                  μπορούμε να συλλέξουμε, να χρησιμοποιήσουμε και να
                  διατηρήσουμε τις πληροφορίες που παρέχονται από αυτούς τους
                  ιστότοπους κοινωνικής δικτύωσης σύμφωνα με την παρούσα
                  Πολιτική Απορρήτου.
                </p>

                <h2>
                  <a id="153" name="153">
                    15.3 Ποιος είναι ο σκοπός και η νομική βάση επεξεργασίας των
                    προσωπικών σας δεδομένων;
                  </a>
                </h2>

                <p>
                  Ανάλογα με το πως αλληλεπιδράτε μαζί μας, θα επεξεργαστούμε τα
                  προσωπικά σας δεδομένα προκειμένου να:
                </p>

                <ul>
                  <li>
                    Παρέχουμε, βελτιώσουμε και να προσωποποιήσουμε τις Υπηρεσίες
                    μας
                  </li>
                  <li>
                    Επικοινωνείτε σχετικά με τον λογαριασμό σας και να σας
                    παρέχουμε εξυπηρέτηση πελατών
                  </li>
                  <li>
                    Εξατομικεύσουμε τις διαφημίσεις και τις επικοινωνίες
                    μάρκετινγκ
                  </li>
                  <li>
                    Προλαμβάνουμε, ανιχνεύουμε, μετριάζουμε και διερευνούμε
                    δόλιες ή παράνομες δραστηριότητες.
                  </li>
                </ul>

                <p>
                  Για τις Υπηρεσίες μας νόμιμη βάση για την επεξεργασία των
                  προσωπικών σας δεδομένων είναι η εκτέλεση της σύμβασής σας.
                  Εάν δεν μας παρέχετε τα προσωπικά σας δεδομένα ή δεν μας τα
                  παρέχετε σωστά, δεν θα είμαστε σε θέση να σας παρέχουμε τις
                  Υπηρεσίες μας. Για σκοπούς μάρκετινγκ και προωθητικών
                  ενεργειών στους πελάτες μας, θεωρούμε ότι έχουμε έννομο
                  συμφέρον να προωθήσουμε την επιχειρηματική μας δραστηριότητα.
                  Όταν επικοινωνούμε μαζί σας μέσω τηλεφώνου, για να
                  διασφαλίσουμε την αποτελεσματικότητα, ενδέχεται να
                  χρησιμοποιούμε αυτόματες ή προηχογραφημένες κλήσεις, εάν έχετε
                  συναινέσει σε μια τέτοια επικοινωνία. Σε περίπτωση που
                  συμπληρώνετε την φόρμα επικοινωνίας του Δικτυακού Τόπου, η
                  επεξεργασία των δεδομένων σας βασίζεται στην δική σας
                  συγκατάθεσης, την οποία παρέχετε αποδεχόμενοι την παρούσα
                  Πολιτική Απορρήτου.
                </p>

                <h2>
                  <a id="154" name="154">
                    15.4 Σε ποιους κοινοποιούμε τα προσωπικά σας δεδομένα;
                  </a>
                </h2>

                <p>
                  Ενδέχεται να κοινοποιήσουμε τις προσωπικές σας πληροφορίες σε
                  άλλα μέλη του εταιρικού ομίλου του MyGreekApp ή σε τρίτα μέρη,
                  τα οποία δεσμεύονται από κατάλληλες συμβάσεις
                  εμπιστευτικότητας. Μπορεί να κοινοποιήσουμε τις προσωπικές σας
                  πληροφορίες σε παρόχους υπηρεσιών, εταίρους χρηματοπιστωτικών
                  ιδρυμάτων για να σας παρέχουν τις υπηρεσίες τους, καθώς και σε
                  υπηρεσίες επιβολής του νόμου, πιστωτικούς οργανισμούς ή
                  γραφεία που εξουσιοδοτούνται από την ισχύουσα εθνική
                  νομοθεσία.
                </p>

                <p>
                  Από τα προσωπικά σας δεδομένα αποκαλύπτουμε μόνο όσα
                  σχετίζονται άμεσα και είναι αναγκαία για την επίτευξη του
                  καθορισμένου σκοπού. Εκτός από τους ανωτέρω αποδέκτες, δεν
                  αποκαλύπτουμε τα προσωπικά σας δεδομένα σε κανένα τρίτο μέρος
                  χωρίς την προηγούμενη έγκρισή σας.
                </p>

                <h2>
                  <a id="155" name="155">
                    15.5 Χρόνος τήρησης των δεδομένων σας
                  </a>
                </h2>

                <p>
                  Θα διατηρήσουμε τις προσωπικές σας πληροφορίες για όσο χρόνο
                  απαιτείται ανάλογα με τον σκοπό της επεξεργασίας. Ενδέχεται να
                  διατηρήσουμε προσωπικές πληροφορίες από λογαριασμούς που έχουν
                  κλείσει προκειμένου να συμμορφωθούμε με τους εθνικούς νόμους,
                  να αποτρέψουμε τυχόν απάτες, να συλλέξουμε οφειλόμενες
                  αμοιβές, να επιλύσουμε διαφορές και προβλήματα, να βοηθήσουμε
                  σε οποιαδήποτε έρευνα, να εφαρμόσουμε τους Όρους και
                  Προϋποθέσεις μας και να προβούμε σε άλλες ενέργειες
                  επιτρεπόμενες ή απαιτούμενες από τους ισχύοντες εθνικούς
                  νόμους. Όταν δεν είναι πλέον απαραίτητο να διατηρούμε τα
                  προσωπικά σας στοιχεία, τα διαγράφουμε με ασφάλεια σύμφωνα με
                  την πολιτική διατήρησης δεδομένων και διαγραφής.
                </p>

                <h2>
                  <a id="156" name="154">
                    15.6 Ανάκληση συγκατάθεσης
                  </a>
                </h2>

                <p>
                  Όταν επικοινωνείτε με εμάς μέσω της φόρμας του Δικτυακού
                  Τόπου, η ολοκλήρωση της ζητηθείσας επικοινωνίας δεν μπορεί να
                  γίνει χωρίς την συγκατάθεσή σας στην παρούσα Πολιτική
                  Απορρήτου. Για να ανακαλέσετε την συγκατάθεσή σας, μπορείτε να
                  επικοινωνήσετε μαζί μας στο info"παπακι"mygreekapp.com.
                </p>

                <p>
                  Όταν λαμβάνετε προωθητικά email μας (προσφορές, κουπόνια
                  κ.α.), μπορείτε να διαγραφείτε από την λίστα παραληπτών
                  οποιαδήποτε στιγμή μέσω των οδηγιών που σας παρέχουμε σε κάθε
                  email μας.
                </p>

                <p>
                  Στην περίπτωση που ανακαλέσετε την συγκατάθεσή σας, αυτό δεν
                  θα επηρεάσει την νομιμότητας της επεξεργασίας που έχει ήδη
                  συντελεστεί.
                </p>

                <h2>
                  <a id="157" name="157">
                    15.7 Διεθνής Διαβίβαση προσωπικών δεδομένων
                  </a>
                </h2>

                <p>
                  Τα προσωπικά σας δεδομένα ενδέχεται να διαβιβασθούν εκτός του
                  Ευρωπαϊκού Οικονομικού Χώρου και να υποβληθούν σε επεξεργασία
                  από εταιρείες τρίτων μερών. Σε κάθε περίπτωση, τα δεδομένα σας
                  θα προστατευθούν επαρκώς υπό κατάλληλες διασφαλίσεις, όπως με
                  την υπογραφή των πρότυπων συμβατικών ρητρών που έχουν εγκριθεί
                  από την Ευρωπαϊκή Επιτροπή.
                </p>

                <h2>
                  <a id="158" name="158">
                    15.8 Μέτρα Ασφαλείας
                  </a>
                </h2>

                <p>
                  Θα επεξεργαζόμαστε τα δεδομένα σας ανά πάσα στιγμή με απόλυτα
                  εμπιστευτικό τρόπο και θα διατηρούμε το υποχρεωτικό καθήκον
                  απορρήτου όσον αφορά τα εν λόγω δεδομένα, σύμφωνα με τις
                  διατάξεις που ορίζονται στους ισχύοντες κανονισμούς. Για το
                  σκοπό αυτό υιοθετούμε μέτρα τεχνικού και οργανωτικού χαρακτήρα
                  που απαιτείται για να εγγυηθούμε την ασφάλεια των δεδομένων
                  σας και να αποτρέψουμε τυχόν αλλοίωση, απώλεια, επεξεργασία ή
                  παράνομη πρόσβαση, βασιζόμενοι στην κατάσταση της τεχνολογίας,
                  τη φύση των αποθηκευμένων δεδομένων και τους κινδύνους στους
                  οποίους εκτίθενται. Μερικά από τα μέτρα που χρησιμοποιούμε
                  είναι τείχη προστασίας και κρυπτογράφηση δεδομένων, έλεγχοι
                  φυσικής πρόσβασης στα κέντρα δεδομένων μας και έλεγχοι
                  εξουσιοδότησης πρόσβασης πληροφοριών, καθώς και ο περιορισμός
                  κάποιων δεδομένων σας με τρόπο ώστε η αναγνώρισή σας από αυτά
                  να καθίσταται αδύνατη.
                </p>

                <h2>
                  <a id="159" name="159">
                    15.9 Άσκηση των Δικαιωμάτων σας
                  </a>
                </h2>

                <p>
                  Αναλαμβάνουμε να κρατήσουμε τα προσωπικά σας δεδομένα
                  εμπιστευτικά και να διασφαλίσουμε ότι μπορείτε να ασκήσετε τα
                  δικαιώματά σας οποιαδήποτε χρονική στιγμή δωρεάν.
                  Συγκεκριμένα, ανεξάρτητα από τον σκοπό ή τη νόμιμη βάση που
                  χρησιμοποιούμε για να επεξεργαστούμε τα δεδομένα σας, έχετε τα
                  ακόλουθα δικαιώματα:
                </p>

                <ul>
                  <li>
                    Να λάβετε επιβεβαίωση σχετικά με το κατά πόσον υποβάλλονται
                    σε επεξεργασία τα προσωπικά σας δεδομένα ή όχι και να
                    ζητήσετε πρόσβαση στα προσωπικά σας δεδομένα που τηρούμε
                  </li>
                  <li>Να διορθώσετε τυχόν ανακριβή ή ελλιπή δεδομένα</li>
                  <li>
                    Να ζητήσετε να διαγράψουμε τα προσωπικά σας δεδομένα στο
                    βαθμό που δεν είναι πλέον απαραίτητα για τον σκοπό για τον
                    οποίο πρέπει να συνεχίσουμε να τα επεξεργαζόμαστε, όπως
                    έχουμε εξηγήσει παραπάνω, ή όταν δεν επιτρέπεται πλέον
                    νόμιμα να τα επεξεργαζόμαστε
                  </li>
                  <li>
                    Να ζητήσετε να περιορίσουμε την επεξεργασία των προσωπικών
                    σας δεδομένων, κάτι που συνεπάγεται ότι σε ορισμένες
                    περιπτώσεις μπορείτε να μας ζητήσετε να αναστείλουμε
                    προσωρινά την επεξεργασία των δεδομένων ή να τα διατηρήσουμε
                    περισσότερο από το απαραίτητο
                  </li>
                  <li>
                    Εάν μας έχετε δώσει τη συγκατάθεσή σας να επεξεργαστούμε τα
                    δεδομένα σας, έχετε επίσης το δικαίωμα να ανακαλέσετε τη
                    συγκατάθεσή σας ανά πάσα στιγμή
                  </li>
                  <li>
                    Να ζητήσετε τη φορητότητα των προσωπικών σας δεδομένων
                  </li>
                </ul>

                <p>
                  Τέλος, σας ενημερώνουμε ότι έχετε το δικαίωμα να υποβάλετε
                  καταγγελία σχετικά με την επεξεργασία των προσωπικών σας
                  δεδομένων ενώπιον του Γραφείου Επιτρόπου Προστασίας Δεδομένων
                  Προσωπικού Χαρακτήρα.
                </p>

                <h2>
                  <a id="1510" name="1510">
                    15.10 Αλλαγές στην Πολιτική Απορρήτου
                  </a>
                </h2>

                <p>
                  Ενδέχεται να τροποποιήσουμε τις πληροφορίες που περιέχονται
                  στην παρούσα Πολιτική Απορρήτου όταν το θεωρούμε απαραίτητο.
                  Εάν το πράξουμε, θα σας ειδοποιήσουμε με διάφορες διαδικασίες
                  μέσω του Δικτυακού Τόπου ή ενδέχεται να σας στείλουμε μια
                  ειδοποίηση στη διεύθυνση ηλεκτρονικού ταχυδρομείου σας, για να
                  μπορείτε να ελέγξετε τις αλλαγές, να τις αξιολογήσετε και,
                  ανάλογα με την περίπτωση, να εναντιωθείτε ή να διαγραφείτε από
                  οποιαδήποτε υπηρεσία ή λειτουργικότητα. Σε κάθε περίπτωση, σας
                  προτείνουμε να ανατρέχετε σε αυτήν την Πολιτική Απορρήτου κατά
                  καιρούς σε περίπτωση που γίνουν μικρές αλλαγές. Θα αλλάξουμε
                  επίσης την ημερομηνία "Τελευταίας ενημέρωσης" στην αρχή αυτής
                  της Πολιτικής Απορρήτου. Τυχόν αλλαγές που κάνουμε στην
                  Πολιτική Απορρήτου θα ισχύουν από την ημερομηνία “Τελευταίας
                  ενημέρωσης” και θα αντικαθιστούν τυχόν προγενέστερες Πολιτικές
                  Απορρήτου.
                </p>

                <p>
                  Εάν έχετε οποιεσδήποτε απορίες ή παρατηρήσεις σχετικά με την
                  προστασία των δεδομένων σας, παρακαλούμε μη διστάσετε να
                  επικοινωνήσετε μαζί μας, αποστέλλοντας ένα e-mail στη
                  διεύθυνση info"παπακι"mygreekapp.com.
                </p>

                <h2>
                  <a id="16" name="16">
                    16. Cookies
                  </a>
                </h2>
                <p>
                  Δεν χρησιμοποιούμε Cookies στην εφαρμογή διότι δεν υπάρχουν
                  στις κινητές εφαρμογές
                </p>

                <p>
                  Παρακαλώ δείτε επίσης τις <a href="#10">παραγράφους 10</a> και
                  <a href="#15">15:Πολιτική Απορρήτου</a>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
          </section>
        </div>
      </main>
    </div>
  );
}
