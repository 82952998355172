import React, { useState } from "react";
import { request } from "../helpers/request";
import Button from "../components/Button";
import { TextField } from "@mui/material";
import toast from "react-hot-toast";
import { regexCollection } from "../helpers/regexCollection";

export default function DeleteUser() {
  const [email, setEmail] = useState();

  async function delUsr() {
    if (regexCollection.email.test(email)) {
      try {
        await request({
          reqtype: "POST",
          endpoint: "/api/gettoken/deleteuser/sendmail",
          data: {
            email,
          },
        });
        toast.success("Σου στείλαμε ενα Email.");
      } catch (e) {
        toast.success("Σφάλμα");
      }
    } else {
      toast.error("Πρόβλημα με το Email");
    }
  }

  return (
    <div className="DeleteUser">
      <h1 style={{ marginBottom: "20px" }}>Διαγραφή λογαριασμού</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "400px",
          gap: "10px",
        }}
      >
        <TextField
          type="email"
          required
          id="outlined-basic"
          label="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.nativeEvent.target.value)}
        />
        <Button children={"Διαγραφή Λογαριασμού"} onClick={delUsr} />
      </div>
    </div>
  );
}
