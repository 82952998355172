import Button from "../../components/Button";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../App";
import { request } from "../../helpers/request";
import { colors } from "../../helpers/maincolors";
import { TextField, Switch, MenuItem } from "@mui/material";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";
import parse from "html-react-parser";

export default function AdminEmails() {
  const { admin } = useContext(Context);
  const [emailList, setEmailList] = useState([]);
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [bodyUpdated, setBodyUpdated] = useState({
    i: undefined,
    v: undefined,
  });
  const [subjectUpdated, setSubjectUpdated] = useState({
    i: undefined,
    v: undefined,
  });
  const [nameUpdated, setNameUpdated] = useState({
    i: undefined,
    v: undefined,
  });
  const [showEditContainer, setShowEditContainer] = useState("");
  const [showCreateEmailContainer, setShowCreateEmailContainer] =
    useState(false);

  async function getEmailList() {
    const response = await request({
      reqtype: "POST",
      endpoint: "/api/admin/emaillist",
      data: {
        userId: admin.user.id,
        adminToken: admin.token,
      },
    });
    if (response?.emails) {
      setEmailList(response.emails);
    }
  }

  async function createEmailEntry() {
    if (body !== "" && name !== "") {
      const response = await request({
        reqtype: "POST",
        endpoint: "/api/admin/create-email",
        data: {
          name: name,
          body: body,
          ...(subject !== "" && { subject }),
          userId: admin.user.id,
          adminToken: admin.token,
        },
      });
      if (response.info === "success") {
        toast.success("Email Created");
        setBody({ i: "", v: "" });
        setSubject({ i: "", v: "" });
        setName({ i: "", v: "" });
      }
    } else {
      toast.error("Body or Name should not be empty");
    }
  }

  async function updateEmailEntry(obj) {
    const response = await request({
      reqtype: "PATCH",
      endpoint: "/api/admin/update-email",
      data: {
        id: obj.id,
        name: obj.name,
        body: obj.body,
        ...(obj.subject && obj.subject !== "" && { subject: obj.subject }),
        userId: admin.user.id,
        adminToken: admin.token,
      },
    });
    if (response.info === "success") {
      toast.success("Updated");
    } else {
      toast.error("something went wrong");
    }
  }
  useEffect(() => {
    getEmailList();
  }, []);

  return (
    <>
      {showCreateEmailContainer ? (
        <>
          <div
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <TextField
              value={name}
              onChange={(e) => setName(e.nativeEvent.target.value)}
              label="Name"
              helperText="Required"
            />
            <TextField
              value={body}
              onChange={(e) => setBody(e.nativeEvent.target.value)}
              label="Body"
              helperText="Required"
              multiline
            />
            <TextField
              value={subject}
              onChange={(e) => setSubject(e.nativeEvent.target.value)}
              label="Subject"
            />
            <Button
              children="Save - creating Email"
              onClick={createEmailEntry}
            />
          </div>
        </>
      ) : emailList.length > 0 ? (
        emailList.map((el, i) => {
          return (
            <div key={i}>
              <div
                style={{
                  padding: 5,
                  marginBottom: 10,
                }}
              >
                <Button
                  color={colors.blue}
                  children={el.name}
                  onClick={() => {
                    setBodyUpdated({
                      v:
                        bodyUpdated.id === el._id && bodyUpdated.v
                          ? bodyUpdated.v
                          : el.body,
                      id: el._id,
                    });
                    setNameUpdated({
                      v:
                        nameUpdated.id === el._id && nameUpdated.v
                          ? nameUpdated.v
                          : el.name,
                      id: el._id,
                    });
                    setSubjectUpdated({
                      v:
                        subjectUpdated.id === el._id && subjectUpdated.v
                          ? subjectUpdated.v
                          : el.subject,
                      id: el._id,
                    });
                    if (showEditContainer === el.name) {
                      setShowEditContainer("");
                    } else {
                      setShowEditContainer(el.name);
                    }
                  }}
                />
              </div>
              {showEditContainer === el.name && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}
                >
                  <div>
                    <div>
                      <TextField
                        label="Name"
                        fullWidth
                        helperText="Required"
                        value={
                          nameUpdated?.v && nameUpdated.id === el._id
                            ? nameUpdated.v
                            : el.name
                        }
                        onChange={(e) =>
                          setNameUpdated({
                            v: e.nativeEvent.target.value,
                            id: el._id,
                          })
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        label="Subject"
                        fullWidth
                        helperText="optional - required for full mails"
                        value={
                          subjectUpdated?.v && subjectUpdated.id === el._id
                            ? subjectUpdated.v
                            : el.subject
                        }
                        onChange={(e) =>
                          setSubjectUpdated({
                            v: e.nativeEvent.target.value,
                            id: el._id,
                          })
                        }
                      />
                    </div>

                    <div>
                      <TextField
                        label="Body"
                        helperText="Required"
                        fullWidth
                        multiline
                        value={
                          bodyUpdated?.v && bodyUpdated.id === el._id
                            ? bodyUpdated.v
                            : el.body
                        }
                        onChange={(e) =>
                          setBodyUpdated({
                            v: e.nativeEvent.target.value,
                            id: el._id,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        padding: "3px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: colors.blue,
                      }}
                    />

                    {parse(bodyUpdated?.v || el?.body)}
                  </div>
                  <Button
                    color={colors.blue}
                    children="Save"
                    onClick={() => {
                      updateEmailEntry({
                        name:
                          nameUpdated.id === el._id && nameUpdated.v
                            ? nameUpdated.v
                            : el.name,
                        subject:
                          subjectUpdated.id === el._id && subjectUpdated.v
                            ? subjectUpdated.v
                            : el.subject,
                        body:
                          bodyUpdated.id === el._id && bodyUpdated.v
                            ? bodyUpdated.v
                            : el.body,
                        id: el._id,
                        id: el._id,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          );
        })
      ) : (
        <Loader />
      )}

      <p>Show Other Container</p>
      <Switch
        value={showCreateEmailContainer}
        onChange={() => setShowCreateEmailContainer(!showCreateEmailContainer)}
      />
    </>
  );
}
