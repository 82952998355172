import "./Start.css";
import { httpIpPort } from "../helpers/httpIpPort";
const { innerWidth } = window;

export default function Start() {
  return (
    <div>
      <main>
        <div id="main">
          {innerWidth < 450 && (
            <div className="app__logo-mobile--wrapper">
              <img
                className="app__logo app__logo--mobile "
                src="https://res.cloudinary.com/dfqf0usk9/image/upload/v1683496549/512x512_cxyqgv.png"
                alt="MyGreekApp"
              />
            </div>
          )}

          <header className="app__header container">
            {innerWidth >= 450 && (
              <div className="app__logo-wrapper">
                <img
                  className="app__logo"
                  src="https://res.cloudinary.com/dfqf0usk9/image/upload/v1683496549/512x512_cxyqgv.png"
                  alt="MyGreekApp"
                />
              </div>
            )}
            <div className="app__infos">
              <p className="app__description">
                Κατάλογος <b>ελληνόφωνων</b> επαγγελματιών και επιχειρήσεων στο{" "}
                <b>εξωτερικό</b>.
              </p>
              <br />
              <p>Κατέβασε την εφαρμογή στο κινητό σου και δοκίμασε την.</p>
              <div className="app__buttons app__buttons--desktop">
                <a
                  rel="noreferrer"
                  href={`${httpIpPort}/api/tracking/tostore?store=google&campaign=homepagestart`}
                  className="app__button-play"
                  target="_blank"
                  title="Get on Google Play"
                >
                  <img
                    src="https://res.cloudinary.com/dfqf0usk9/image/upload/v1699973368/google-play-badge_1_dva2ol.png"
                    alt="Get on Google Play"
                  />
                </a>
                <a
                  rel="noreferrer"
                  href={`${httpIpPort}/api/tracking/tostore?store=apple&campaign=homepagestart`}
                  className="app__button-ios"
                  target="_blank"
                  title="Get on Appstore"
                >
                  <img
                    src="https://res.cloudinary.com/dfqf0usk9/image/upload/v1699972563/Download_on_the_App_Store_Badge_US-UK_blk_092917_qqmw3a.png"
                    alt="Get on Appstore"
                  />
                </a>
              </div>
              <div className="app__buttons app__buttons--mobile">
                <a
                  rel="noreferrer"
                  href={`${httpIpPort}/api/tracking/tostore?store=google&campaign=homepagestart`}
                  className="app__button-play"
                  target="_blank"
                  title="Get on Google Play"
                >
                  <img
                    src="https://res.cloudinary.com/dfqf0usk9/image/upload/v1699973368/google-play-badge_1_dva2ol.png"
                    alt="Get on Google Play"
                  />
                </a>
                <a
                  rel="noreferrer"
                  href={`${httpIpPort}/api/tracking/tostore?store=apple&campaign=homepagestart`}
                  className="app__button-ios"
                  target="_blank"
                  title="Get on Appstore"
                >
                  <img
                    src="https://res.cloudinary.com/dfqf0usk9/image/upload/v1699972563/Download_on_the_App_Store_Badge_US-UK_blk_092917_qqmw3a.png"
                    alt="Get on Appstore"
                  />
                </a>
              </div>
            </div>
          </header>

          <section className="app__screenshots app__section">
            <div className="app__screenshots-wrapper container-desktop">
              <div className="app__screenshots-list">
                <img
                  src="https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,h_701,q_48/v1681916019/landingpage/homepage_urpkr6.png"
                  className="app__screenshot"
                  alt="homepage"
                />
                <img
                  src="https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,h_701,q_48/v1681916020/landingpage/Map_imeczx.png"
                  className="app__screenshot"
                  alt="map"
                />
                <img
                  src="https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,h_701,q_48/v1681916019/landingpage/single_companz_ghahsx.png"
                  className="app__screenshot"
                  alt="company"
                />
                <img
                  src="https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,h_701,q_48/v1681916019/landingpage/Favorites_vcozat.png"
                  className="app__screenshot"
                  alt="favs"
                />
                <img
                  src="https://res.cloudinary.com/dfqf0usk9/image/upload/c_scale,h_701,q_48/v1681916020/landingpage/Profile_udc8wq.png"
                  className="app__screenshot"
                  alt="profile"
                />
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
