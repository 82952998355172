import "./Imprint.css";
export default function Imprint() {
  return (
    <div className="Imprint">
      <h2>Αποτύπωμα & Επικοινωνία</h2>
      <p>
        Cimbernstraße 13a,
        <br />
        81377 Μόναχο, Γερμανία,
        <br />
        Τηλέφωνο: + 49 176 567 637 60
        <br />
        Ust-IdNr.: DE329284979
        <br />
        email: info'παπάκι'mygreekapp.com
        <br />
        Εκπροσωπούμενη από:
        <br />
        Μιχαήλ Μαγγασάροβ
        <br />
        Σχεδιασμός & εφαρμογή:
        <br />
        Μιχαήλ Μαγγασάροβ
      </p>
      <p style={{ marginTop: 10 }}>
        <b>Ευθύνη για το περιεχόμενο: </b>
        <br></br>Σύμφωνα με την ισχύουσα νομοθεσία, φέρουμε ευθύνη για το
        περιεχόμενο που παρέχουμε στον ιστότοπό μας. <br></br>Ωστόσο, δεν
        φέρουμε ευθύνη για την ακρίβεια, την πληρότητα ή την επικαιρότητα των
        παρεχόμενων πληροφοριών.<br></br> Οι απόψεις που εκφράζονται από τους
        συντάκτες μας ανήκουν σε αυτούς και δεν αντιπροσωπεύουν κατ' ανάγκη τις
        δικές μας απόψεις.
      </p>
      <p style={{ marginTop: 10 }}>
        <b>Δικαιώματα πνευματικής ιδιοκτησίας:</b> <br></br>Το περιεχόμενο αυτού
        του ιστότοπου, συμπεριλαμβανομένων του κειμένου, των γραφικών,
        <br></br> των λογοτύπων και των εικόνων, προστατεύεται από πνευματικά
        δικαιώματα και ανήκει στον ιδιοκτήτη του ιστότοπου.<br></br>{" "}
        Απαγορεύεται η αντιγραφή, η αναπαραγωγή ή η χρήση του περιεχομένου χωρίς
        την προηγούμενη γραπτή άδεια του ιδιοκτήτη.
      </p>
    </div>
  );
}
