import React, { useState } from "react";
import { request } from "../helpers/request";
import Button from "../components/Button";
import { TextField } from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function RegularLogin() {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  async function login() {
    if (username) {
      try {
        let response = await request({
          reqtype: "POST",
          endpoint: "/login",
          data: {
            password,
            username: username.trim(),
          },
        });
        if (response.status === "AUTHENTICATED") {
          const storageObject = {
            email: response.email,
            token: response.token,
            user: response.user,
            expires: Date.now() + 86400000,
            googleApiKey: response.googleApiKey,
          };
          toast.success("Logged In");
          try {
            localStorage.setItem(
              "mygreekappadmin",
              JSON.stringify(storageObject)
            );
            setUsername("");
            setPassword("");
            navigate("/overview");
          } catch (error) {
            console.log(error, "error setting asyncstorage");
          }
        } else if (response.status === "USER NOT EXISTING") {
          toast.error("USER NOT EXISTING");
        } else if (response.status === "USER NOT VERIFIED") {
          toast.error("USER NOT VERIFIED");
        } else if (response.status === "WRONG PASSWORD") {
          toast.error("WRONG PASSWORD");
        } else {
          toast.error("This didn't work.");
        }
      } catch (e) {
        console.log("cant fetch user api", e);
        toast.error("This didn't work.");
      }
    } else {
      toast.error("This didn't work.");
    }
  }

  return (
    <div
      style={{
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <TextField
        autoFocus
        required
        id="outlined-basic"
        variant="outlined"
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.nativeEvent.target.value)}
      />
      <TextField
        type="password"
        required
        id="outlined-basic"
        label="Password"
        variant="outlined"
        value={password}
        onKeyDown={(e) => {
          e.key === "Enter" && login();
        }}
        onChange={(e) => setPassword(e.nativeEvent.target.value)}
      />
      <Button children={"Login"} onClick={login} />
    </div>
  );
}
