import Button from "@mui/material/Button";
import { colors } from "../helpers/maincolors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function Btn({
  size = "medium",
  width = window.innerWidth < 500
    ? "100%"
    : window.innerWidth < 700
    ? "70%"
    : "30%",
  variant = "contained",
  children = "Label",
  color = colors.main,
  href,
  onClick,
  onKeyDown,
  style,
  className = "Button",
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={className}
        style={({ maxWidth: width }, style)}
        onClick={onClick}
        href={href}
        size={size}
        color="primary"
        variant={variant}
        onKeyDown={onKeyDown}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
}
